import { ENButton } from "en-react/dist/src/components/Button"
import { ENCheckboxItem } from "en-react/dist/src/components/CheckboxItem"
import { ENDialog } from "en-react/dist/src/components/Dialog"
import { ENHeading } from "en-react/dist/src/components/Heading"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import appLogo from "src/assets/images/ztFullAppLogo.svg"
import { hideToaster } from "src/components/TopNav"
import { CHECK_EULA_ACCEPTED } from "src/constants"
import { AuthRoutesMap, END_USER_BASE_ROUTE_PREFIX, Roles } from "src/routes/config"
import { patchRequest } from "src/services"
import logger from "src/services/logger"
import CircularLoader from "src/shared/components/CicularLoader/CircularLoader"
import ZtnaIcon from "src/shared/components/Icons/ZtnaIcon"
import ZtnaButton from "src/shared/components/ZtnaButton"
import { RootState } from "src/store"
import { resetAuth } from "src/store/auth"
import { setIsSideNavOpened, setLayout, setUpgradeRelayState } from "src/store/ui"
import theme from "src/theme"
import { deleteCookie, getXiqUrl } from "src/utils"
import { FEATURE_FLAGS_LOCAL_STORAGE_KEY } from "src/utils/constants"
import { useSWRConfig } from "swr"
import { logoutUser } from "../Login/Login.service"
import { useEulaStyles } from "./Eula.styles"

const Eula: React.FC = () => {
  const classes = useEulaStyles()

  const userData = JSON.parse(localStorage.getItem("userData") || "{}")

  const isEulaAccepted = localStorage.getItem("userData")
    ? !!JSON.parse(localStorage.getItem("userData") || "{}")?.workspaceData?.isEulaAccepted
    : false
  const [open, setOpen] = useState(!isEulaAccepted)
  const [awaitingAcceptance, setAwaitingAcceptance] = useState(false)
  const [accepted, setAccepted] = useState(false)

  const { role } = useSelector((state: RootState) => state.auth.user)
  const { cache } = useSWRConfig()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onSubmitCLick = async () => {
    try {
      setAwaitingAcceptance(true)
      const response = await patchRequest(CHECK_EULA_ACCEPTED, {
        is_eula_accepted: true,
      })
      if (response.status === 200) {
        localStorage.setItem(
          "userData",
          JSON.stringify({
            ...userData,
            workspaceData: { ...(userData?.workspaceData ? userData?.workspaceData : {}), isEulaAccepted: true },
          }),
        )
        setOpen(false)
      }
    } catch (error) {
      console.error("Eula acceptance failed.")
    } finally {
      setAwaitingAcceptance(false)
    }
  }

  const onDeclineClick = () => {
    logoutUser(role)
      .then(() => {
        logger.info("sign-out-btn")
        ;(cache as any).clear()
        localStorage.removeItem("isSideNavOpened")
        localStorage.removeItem("analyticsFilter")
        localStorage.removeItem("sessionId")
        localStorage.removeItem("userData")
        localStorage.removeItem(FEATURE_FLAGS_LOCAL_STORAGE_KEY)
        dispatch(setLayout(undefined))
        dispatch(setIsSideNavOpened(false))
        dispatch(setUpgradeRelayState("pending"))
        deleteCookie("sessionid")
        deleteCookie("api_access_token")
        deleteCookie("oauth2AccessToken")
        hideToaster()
        if (role === Roles.CUSTOMER_SUPER_ADMIN) {
          window.location.replace(`${localStorage.getItem("xiqLogoutUrl")}/sso/logout`)
        } else {
          resetUserData(window.location.pathname)
        }
      })
      .catch((err) => logger.error("sign-out-btn", { error: err }))
  }

  const resetUserData = (currentRoute: string) => {
    ;(cache as any).clear()
    localStorage.removeItem("isSideNavOpened")
    localStorage.removeItem("analyticsFilter")
    localStorage.removeItem("sessionId")
    localStorage.removeItem("userData")
    localStorage.removeItem(FEATURE_FLAGS_LOCAL_STORAGE_KEY)
    dispatch(setLayout(undefined))
    dispatch(setIsSideNavOpened(false))
    dispatch(setUpgradeRelayState("pending"))
    deleteCookie("sessionid")
    deleteCookie("api_access_token")
    deleteCookie("oauth2AccessToken")
    hideToaster()
    if (role === Roles.CUSTOMER_SUPER_ADMIN && !currentRoute.includes(END_USER_BASE_ROUTE_PREFIX)) {
      window.location.href = getXiqUrl()
    } else if (currentRoute.includes(END_USER_BASE_ROUTE_PREFIX)) {
      window.location.href = `${window.location.origin}${AuthRoutesMap.LOGIN.absolutePath}`
    } else
      navigate(
        role === Roles.SUPER_ADMIN ? AuthRoutesMap.ZTNA_ADMIN_LOGIN.absolutePath : AuthRoutesMap.LOGIN.absolutePath,
      )

    dispatch(resetAuth())
  }

  const handleEulaAccepted = async () => {
    setAccepted(true)
  }

  return (
    <ENDialog isActive={open} width={1270} hideCrossIcon disableClickOutside hasBackdrop>
      <div className={classes.mainContainer}>
        <div className={classes.header}>
          <ZtnaIcon name="xcuztna" color={theme.palette.neutralLight[16]} height={25} width={25} />
          <img className={classes.brandLogo} alt="logo" src={appLogo} />
        </div>
        <ENHeading variant="md">
          <span>End User License Agreement and Privacy Notice</span>
        </ENHeading>
        <div className={classes.iframeContainer}>
          <iframe
            src="https://extr-p-001.sitecorecontenthub.cloud/api/public/content/11bb0c6dffdf49319aedd05df9dfcfe5?v=3be62fa7"
            width="80%"
            height="600px"
            title="PDF-file"
          />
          <div className={classes.acknowledgeContainer}>
            <ENCheckboxItem onChange={handleEulaAccepted} />
            <span>I have read and agree to the terms and conditions.</span>
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <ENButton variant="secondary" onClick={onDeclineClick}>
            Decline
          </ENButton>
          <ZtnaButton
            disabled={!accepted || awaitingAcceptance}
            onClick={onSubmitCLick}
            title="Submit"
            endIcon={awaitingAcceptance && <CircularLoader />}
          />
        </div>
      </div>
    </ENDialog>
  )
}
export default Eula
