import {
  CHECK_FOR_FORCE_LOGOUT,
  CHECK_FOR_VALID_SESSION_END_USER,
  GOOGLE_AUTH_URL_LOGIN,
  GOOGLE_AUTH_URL_SIGNUP_INVITE,
  LOGIN_URL,
  LOGOUT_URL,
  MICROSOFT_OIDC_AUTH_URL_LOGIN,
  MICROSOFT_OIDC_AUTH_URL_SIGNUP_INVITE,
  SAML_AUTH_URL_LOGIN,
  SAML_AUTH_URL_SIGNUP_INVITE,
  TENANT_ADMIN_BASE_URL,
  XIQ_LOGOUT_URL,
} from "src/constants"
import { Roles, RoleValueType } from "src/routes/config"
import { getRequest, postRequest } from "src/services"

type LoginResponseType = {
  loginUrl: string
}
type LoginPayloadType = {
  email?: string
  return_url?: string | null
  UAAV?: string
  UAA?: string
  platform?: string | null
  auth_token?: string
}
export const parseUserData = ({
  email,
  role,
  firstName,
  lastName,
  sessionId,
  workspaceUrl,
  workspaceId,
  userFirstLogin,
  id,
}: {
  email: string
  role: { name: string; code: string }
  firstName: string
  lastName: string
  sessionId: string
  workspaceUrl: string
  workspaceId: number
  userFirstLogin: boolean
  id?: number
}): any => {
  return {
    email,
    role: role.code,
    firstName,
    lastName,
    sessionId,
    workspaceUrl,
    workspaceId,
    userFirstLogin,
    id,
  }
}

function setCookie(name: string, value: string, days: number) {
  let expires = ""
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = "; expires=" + date.toUTCString()
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/"
}

export const authenticateUser = async (
  body: {
    email: string
    password: string
    userAuthenticationCode?: string
  },
  isZTNAAdmin: boolean,
): Promise<{
  id: number | undefined
  email: string
  role: RoleValueType
  firstName: string
  lastName: string
  sessionId: string
  workspaceUrl: string
  workspaceId: number
  userFirstLogin: boolean
}> => {
  const url = window.location.pathname.includes("/zta") ? `${TENANT_ADMIN_BASE_URL}${LOGIN_URL}` : LOGIN_URL
  const resp = await postRequest(url, {
    email: body.email,
    password: body.password,
    is_ztna_admin: isZTNAAdmin,
    user_authentication_code: body?.userAuthenticationCode,
  })

  if (process.env.NODE_ENV !== "production") {
    setCookie("sessionid", resp.data.payload.sessionId, 1)
  }

  if (resp?.data?.payload?.workspace?.id) localStorage.removeItem("firstTimeLogin")
  else localStorage.setItem("firstTimeLogin", "true")

  return {
    ...parseUserData(resp.data.payload),
    userFirstLogin: resp.data?.payload?.workspace?.id ? true : false,
  }
}

export const logoutUser = async (role: RoleValueType): Promise<{ status: boolean }> => {
  let url = ""
  if (role === Roles.CUSTOMER_SUPER_ADMIN) {
    url = url + `${localStorage.getItem("xcdUrl")}/oauth/logout`
  } else {
    url = url + `${LOGOUT_URL}?is_end_user=${role === Roles.END_USER || undefined}`
  }

  const resp = await getRequest(url, undefined, role === Roles.CUSTOMER_SUPER_ADMIN ? false : true)
  return {
    status: resp.data.status,
  }
}
export const logoutEndUserOnly = async (): Promise<{ status: boolean }> => {
  const resp = await getRequest(LOGOUT_URL)
  return {
    status: resp.data.status,
  }
}

export const logoutSsoUser = async (): Promise<{ status: boolean }> => {
  const resp = await getRequest(XIQ_LOGOUT_URL)
  return {
    status: resp.data.status,
  }
}

export const checkForForceLogout = async ({ sessionId }: { sessionId: string }): Promise<void> => {
  const { role } = JSON.parse(localStorage.getItem("userData") || "{}")
  const url =
    role === Roles.CUSTOMER_SUPER_ADMIN
      ? `${TENANT_ADMIN_BASE_URL}${CHECK_FOR_FORCE_LOGOUT(sessionId)}`
      : CHECK_FOR_FORCE_LOGOUT(sessionId)
  await getRequest(url)
}

export const checkForValidSession = async (): Promise<void> => {
  await getRequest(CHECK_FOR_VALID_SESSION_END_USER)
}

export const signInWithGoogle = async ({
  email,
  return_url,
  UAAV,
  UAA,
  platform,
}: LoginPayloadType): Promise<LoginResponseType> => {
  const resp = await postRequest(GOOGLE_AUTH_URL_LOGIN, { email, return_url, UAAV, UAA, platform })
  return {
    loginUrl: resp.data.payload.loginUrl,
  }
}

export const signInWithMicrosoft = async ({
  email,
  return_url,
  UAAV,
  UAA,
  platform,
}: LoginPayloadType): Promise<LoginResponseType> => {
  const resp = await postRequest(MICROSOFT_OIDC_AUTH_URL_LOGIN, { email, return_url, UAAV, UAA, platform })
  return {
    loginUrl: resp.data.payload.loginUrl,
  }
}

export const signInWithSAML = async ({
  email,
  return_url,
  UAAV,
  UAA,
  platform,
}: LoginPayloadType): Promise<LoginResponseType> => {
  const resp = await postRequest(SAML_AUTH_URL_LOGIN, { email, return_url, UAAV, UAA, platform })
  return {
    loginUrl: resp.data.payload.loginUrl,
  }
}

export const signUpWithSAML = async ({
  email,
  UAA,
  UAAV,
  auth_token,
  platform,
}: LoginPayloadType): Promise<LoginResponseType> => {
  const resp = await postRequest(SAML_AUTH_URL_SIGNUP_INVITE, { email, UAA, UAAV, auth_token, platform })
  return {
    loginUrl: resp.data.payload.loginUrl,
  }
}

export const signUpWithMicrosoft = async ({
  email,
  UAA,
  UAAV,
  auth_token,
  platform,
}: LoginPayloadType): Promise<LoginResponseType> => {
  const resp = await postRequest(MICROSOFT_OIDC_AUTH_URL_SIGNUP_INVITE, { email, UAA, UAAV, auth_token, platform })
  return {
    loginUrl: resp.data.payload.loginUrl,
  }
}

export const signUpWithGoogle = async ({
  email,
  UAA,
  UAAV,
  auth_token,
  platform,
}: LoginPayloadType): Promise<LoginResponseType> => {
  const resp = await postRequest(GOOGLE_AUTH_URL_SIGNUP_INVITE, { email, UAA, UAAV, auth_token, platform })
  return {
    loginUrl: resp.data.payload.loginUrl,
  }
}
