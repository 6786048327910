import theme from "src/theme"

export const CalendarIcon: React.FC<{ color?: string }> = ({ color = theme.palette.primary[600] }): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16.021" height="18.31" viewBox="0 0 16.021 18.31">
    <path
      fill={color}
      d="M0 16.594a1.717 1.717 0 0 0 1.717 1.716H14.3a1.717 1.717 0 0 0 1.717-1.717V6.866H0zm11.444-7.01a.43.43 0 0 1 .429-.429H13.3a.43.43 0 0 1 .429.429v1.43a.43.43 0 0 1-.429.429h-1.43a.43.43 0 0 1-.429-.429zm0 4.578a.43.43 0 0 1 .429-.429H13.3a.43.43 0 0 1 .429.429v1.43a.43.43 0 0 1-.429.429h-1.43a.43.43 0 0 1-.429-.429zM6.866 9.584a.43.43 0 0 1 .434-.429h1.43a.43.43 0 0 1 .429.429v1.43a.43.43 0 0 1-.429.429H7.3a.43.43 0 0 1-.429-.429zm0 4.578a.43.43 0 0 1 .429-.429h1.43a.43.43 0 0 1 .429.429v1.43a.43.43 0 0 1-.429.429H7.3a.43.43 0 0 1-.429-.429zM2.289 9.584a.43.43 0 0 1 .429-.429h1.43a.43.43 0 0 1 .429.429v1.43a.43.43 0 0 1-.429.429h-1.43a.43.43 0 0 1-.429-.429zm0 4.578a.43.43 0 0 1 .429-.429h1.43a.43.43 0 0 1 .429.429v1.43a.43.43 0 0 1-.429.429h-1.43a.43.43 0 0 1-.429-.429zM14.3 2.289h-1.712V.572A.574.574 0 0 0 12.016 0h-1.144a.574.574 0 0 0-.572.572v1.717H5.722V.572A.574.574 0 0 0 5.15 0H4.005a.574.574 0 0 0-.572.572v1.717H1.717A1.717 1.717 0 0 0 0 4.005v1.717h16.021V4.005A1.717 1.717 0 0 0 14.3 2.289z"
    />
  </svg>
)

export const CalendarIcon2: React.FC<{ color?: string; height?: string; width?: string }> = ({
  color = theme.palette.grey.A200,
  height = "20",
  width = "20",
}): JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8333 2.33366H15V1.50033C15 1.04199 14.625 0.666992 14.1667 0.666992C13.7083 0.666992 13.3333 1.04199 13.3333 1.50033V2.33366H6.66667V1.50033C6.66667 1.04199 6.29167 0.666992 5.83333 0.666992C5.375 0.666992 5 1.04199 5 1.50033V2.33366H4.16667C3.24167 2.33366 2.50833 3.08366 2.50833 4.00033L2.5 15.667C2.5 16.5837 3.24167 17.3337 4.16667 17.3337H15.8333C16.75 17.3337 17.5 16.5837 17.5 15.667V4.00033C17.5 3.08366 16.75 2.33366 15.8333 2.33366ZM15.8333 14.8337C15.8333 15.292 15.4583 15.667 15 15.667H5C4.54167 15.667 4.16667 15.292 4.16667 14.8337V6.50033H15.8333V14.8337ZM5.83333 8.16699H7.5V9.83366H5.83333V8.16699ZM9.16667 8.16699H10.8333V9.83366H9.16667V8.16699ZM12.5 8.16699H14.1667V9.83366H12.5V8.16699Z"
      fill={color}
    />
  </svg>
)

export const CameraIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14.415" height="12.613" viewBox="0 0 14.415 12.613">
    <path
      fill={theme.palette.primary[600]}
      d="M14.415 5.4v8.109a1.352 1.352 0 0 1-1.351 1.351H1.351A1.352 1.352 0 0 1 0 13.512V5.4a1.352 1.352 0 0 1 1.351-1.348h2.478l.346-.926a1.35 1.35 0 0 1 1.264-.876h3.534a1.35 1.35 0 0 1 1.264.876l.349.926h2.478A1.352 1.352 0 0 1 14.415 5.4zm-3.829 4.058a3.379 3.379 0 1 0-3.379 3.379 3.381 3.381 0 0 0 3.379-3.379zm-.9 0A2.478 2.478 0 1 1 7.208 6.98a2.481 2.481 0 0 1 2.477 2.478z"
      transform="translate(0 -2.25)"
    />
  </svg>
)

export const CaretIcon: React.FC<{ color?: string; width?: string; height?: string }> = ({
  color = theme.palette.grey[800],
  width = "10.795px",
  height = "5.398px",
}): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 10.795 5.398">
    <path id="Path" d="M0,0,5.4,5.4,10.8,0Z" fill={color} />
  </svg>
)

export const CaretIcon2 = (): JSX.Element => (
  <svg width="7" height="4" viewBox="0 0 7 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.863476 1.48977L3.17237 3.74533C3.25487 3.82606 3.35286 3.89011 3.46072 3.93381C3.56858 3.97751 3.68421 4 3.80098 4C3.91775 4 4.03337 3.97751 4.14123 3.93381C4.2491 3.89011 4.34708 3.82606 4.42958 3.74533L6.74003 1.48977C6.86449 1.36744 6.94911 1.21188 6.98323 1.04266C7.01735 0.873445 6.99945 0.698148 6.93178 0.538853C6.86411 0.379558 6.74969 0.243389 6.60295 0.147499C6.45621 0.0516098 6.28369 0.000288547 6.10715 0H1.4878C1.31148 0.000851592 1.13938 0.0527236 0.993221 0.149066C0.847063 0.245408 0.733407 0.3819 0.666606 0.541304C0.599804 0.700708 0.582852 0.875875 0.617892 1.04468C0.652931 1.21349 0.73839 1.36837 0.863476 1.48977Z"
      fill={theme.palette.grey.A200}
    />
  </svg>
)

export const CaseIcon = ({ color = theme.palette.grey.A200 }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7515 4.44824C17.0099 5.08659 17.143 5.77078 17.143 6.46174H14.9737C14.9737 6.06222 14.8968 5.66663 14.7473 5.29754C14.5979 4.92844 14.3789 4.59306 14.1028 4.31057C13.8266 4.02807 13.4988 3.80399 13.1381 3.6511C12.7772 3.49822 12.3906 3.41952 12.0001 3.41952C11.6096 3.41952 11.2229 3.49822 10.8621 3.6511C10.5014 3.80399 10.1736 4.02808 9.89744 4.31057C9.62132 4.59306 9.40229 4.92844 9.25285 5.29754C9.10342 5.66663 9.0265 6.06222 9.0265 6.46174H6.85723C6.85723 5.77078 6.99025 5.08659 7.24871 4.44824C7.50715 3.80987 7.88598 3.22984 8.36353 2.74126C8.8411 2.25269 9.40804 1.86513 10.032 1.60071C10.656 1.33629 11.3247 1.2002 12.0001 1.2002C12.6755 1.2002 13.3442 1.33629 13.9681 1.60071C14.5921 1.86513 15.1591 2.25268 15.6366 2.74126C16.1142 3.22984 16.493 3.80987 16.7515 4.44824ZM2.4 6.46176C1.07452 6.46176 0 7.53628 0 8.86176V21.6002C0 22.9257 1.07452 24.0002 2.4 24.0002H21.6C22.9255 24.0002 24 22.9257 24 21.6002V8.86176C24 7.53628 22.9255 6.46176 21.6 6.46176H2.4Z"
      fill={color}
      fillOpacity="0.28"
    />
    <path
      d="M4.6291 9.11475H19.3719C19.5376 9.11475 19.6719 9.24906 19.6719 9.41475V21.0455C19.6719 21.2112 19.5376 21.3455 19.3719 21.3455H4.6291C4.46342 21.3455 4.3291 21.2112 4.3291 21.0455V9.41475C4.3291 9.24906 4.46342 9.11475 4.6291 9.11475Z"
      fill={color}
      fillOpacity="0.06"
      stroke={color}
      strokeWidth="1.5"
    />
  </svg>
)

export const CheckCircleIcon = ({ width = "17", height = "17", color = theme.palette.success[500] }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16.667 16.667">
    <path
      data-name="Check Circle"
      d="M10 1.667A8.333 8.333 0 1 0 18.334 10 8.336 8.336 0 0 0 10 1.667zm0 15A6.667 6.667 0 1 1 16.667 10 6.676 6.676 0 0 1 10 16.667zm3.825-10.35-5.491 5.492-2.159-2.15L5 10.834l3.333 3.333L15 7.5z"
      transform="translate(-1.667 -1.667)"
      style={{ fill: color }}
    />
  </svg>
)

export const ChromebookIcon = ({ width = "42", height = "40", color = theme.palette.grey.A200 }) => (
  <svg width={width} height={height} viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.3701 16.8966L5.0547 7.42831C8.98944 2.65386 14.8915 0.0246851 20.9259 0.000490277C24.4225 -0.0237046 27.9935 0.847311 31.2422 2.67806C34.8297 4.71042 37.5576 7.62994 39.2935 10.985L22.3229 10.1139C17.5202 9.83974 12.949 12.477 11.3701 16.8966ZM14.0897 20.0016C14.0897 23.7276 17.1813 26.7439 21.0003 26.7439C24.8193 26.7439 27.9109 23.7276 27.9109 20.0016C27.9109 16.2756 24.8193 13.2593 21.0003 13.2593C17.1813 13.2593 14.0897 16.2675 14.0897 20.0016ZM40.1201 12.8076L28.5722 13.3883C31.7051 16.9611 31.7547 22.1146 29.1178 26.0664L19.8513 39.9704C23.6951 40.172 27.6546 39.3494 31.2422 37.317C40.1201 32.3168 43.716 21.8323 40.1201 12.8076ZM11.552 23.8405L3.83957 9.0413C1.73167 12.1866 0.5 15.961 0.5 20.0016C0.5 30.0021 8.00576 38.2848 17.8178 39.7527L23.0834 29.6876C18.322 30.5586 13.726 28.0101 11.552 23.8405Z"
      fill={color}
    />
  </svg>
)

export const CircleAddIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15">
    <g id="Group_4263" data-name="Group 4263" transform="translate(-638.239 -273.625)">
      <circle
        id="Ellipse_547"
        data-name="Ellipse 547"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(638.239 273.625)"
        fill={theme.palette.success.main}
      />
      <g id="Group_4239" data-name="Group 4239" transform="translate(0 -.906)">
        <path
          id="Path_2548"
          data-name="Path 2548"
          fill="none"
          stroke={theme.palette.neutralLight[16]}
          strokeLinecap="round"
          strokeWidth="1.5px"
          d="M646.88 276.235v7.992"
          transform="translate(-1 1.805)"
        />
        <path
          id="Path_2549"
          data-name="Path 2549"
          fill="none"
          stroke={theme.palette.neutralLight[16]}
          strokeLinecap="round"
          strokeWidth="1.5px"
          d="M646.88 276.235v8"
          transform="rotate(90 645.486 280.629)"
        />
      </g>
    </g>
  </svg>
)

export const CircleTickIcon: React.FC<{
  color?: string
  height?: string
  width?: string
  viewBox?: string
}> = ({ color = "#dedede", height = 14, width = 14, viewBox = "0 0 16 16" }): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox={viewBox}>
    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
  </svg>
)

export const CircleGreenTickIcon: React.FC<{
  color?: string
  height?: string
  width?: string
  viewBox?: string
}> = ({ color = "#dedede", height = 14, width = 14, viewBox = "0 0 16 16" }): JSX.Element => (
  <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.1917 5.05833C12.4333 5.3 12.4333 5.7 12.1917 5.94167L7.19167 10.9417C6.95 11.1833 6.55 11.1833 6.30833 10.9417L3.80833 8.44167C3.56667 8.2 3.56667 7.8 3.80833 7.55833C4.05 7.31667 4.45 7.31667 4.69167 7.55833L6.75 9.61667L11.3083 5.05833C11.55 4.81667 11.95 4.81667 12.1917 5.05833ZM15.5 8C15.5 12.1417 12.1417 15.5 8 15.5C3.85833 15.5 0.5 12.1417 0.5 8C0.5 3.85833 3.85833 0.5 8 0.5C12.1417 0.5 15.5 3.85833 15.5 8ZM14.25 8C14.25 4.55 11.45 1.75 8 1.75C4.55 1.75 1.75 4.55 1.75 8C1.75 11.45 4.55 14.25 8 14.25C11.45 14.25 14.25 11.45 14.25 8Z" fill="#75BF63"/>
</svg>
)

export const CircleCheckIcon = ({ color = theme.palette.grey.A200 }): JSX.Element => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 18C4.03748 18 0 13.9625 0 9C0 4.03748 4.03748 0 9 0C13.9625 0 18 4.03748 18 9C18 13.9625 13.9625 18 9 18ZM9 1.125C4.65764 1.125 1.125 4.65764 1.125 9C1.125 13.3424 4.65764 16.875 9 16.875C13.3424 16.875 16.875 13.3424 16.875 9C16.875 4.65764 13.3424 1.125 9 1.125ZM7.71019 12.2102L13.3352 6.58519C13.555 6.36539 13.555 6.00947 13.3352 5.78981C13.1154 5.57016 12.7595 5.57002 12.5398 5.78981L7.3125 11.0171L5.46019 9.16481C5.24039 8.94501 4.88447 8.94501 4.66481 9.16481C4.44516 9.38461 4.44502 9.74053 4.66481 9.96019L6.91481 12.2102C7.02464 12.32 7.16864 12.375 7.3125 12.375C7.45636 12.375 7.60036 12.32 7.71019 12.2102Z"
      fill={color}
    />
  </svg>
)

export const CircleWarningIcon: React.FC<{ width?: string; height?: string }> = ({
  width = "18.189px",
  height = "18.189",
}): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 -1.5 22.432 22.432">
    <path
      data-name="Icon material-error"
      d="M12.095 3a9.095 9.095 0 1 0 9.095 9.095A9.1 9.1 0 0 0 12.095 3zM13 16.642h-1.815v-1.819H13zM13 13h-1.815V7.547H13z"
      transform="translate(-3 -3)"
      fill={theme.palette.grey[800]}
    />
  </svg>
)

export const ClientObjectIcon = (): JSX.Element => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="9" fill={theme.palette.secondary[400]} />
    <circle cx="10" cy="10" r="9" stroke={theme.palette.grey.A200} />
    <circle cx="10.0003" cy="10.0003" r="5.39286" fill="#DDE3E9" stroke={theme.palette.grey.A200} strokeWidth="1.5" />
  </svg>
)

export const ClockIcon: React.FC<{ color?: string; width?: string; height?: string }> = ({
  color = theme.palette.grey[800],
  width = "26",
  height = "25",
}): JSX.Element => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.9897 2.08374C7.23975 2.08374 2.5835 6.75042 2.5835 12.5004C2.5835 18.2504 7.23975 22.9171 12.9897 22.9171C18.7501 22.9171 23.4169 18.2504 23.4169 12.5004C23.4169 6.75042 18.7501 2.08374 12.9897 2.08374ZM13.0001 20.8337C8.396 20.8337 4.66683 17.1046 4.66683 12.5004C4.66683 7.89624 8.396 4.16708 13.0001 4.16708C17.6044 4.16708 21.3335 7.89624 21.3335 12.5004C21.3335 17.1046 17.6044 20.8337 13.0001 20.8337ZM12.771 7.29208H12.7085C12.2918 7.29208 11.9585 7.62541 11.9585 8.04208V12.9588C11.9585 13.3234 12.146 13.6671 12.4689 13.8546L16.7919 16.4484C17.146 16.6566 17.6044 16.5525 17.8126 16.1984C18.0314 15.8441 17.9169 15.3754 17.5523 15.1671L13.521 12.7713V8.04208C13.521 7.62541 13.1876 7.29208 12.771 7.29208Z"
      fill={color}
    />
  </svg>
)

export const CloseIcon: React.FC<{ width?: string; height?: string; color?: string; strokeWidth?: string }> = ({
  width = "10.7px",
  height = "10.7px",
  color = theme.palette.grey[800],
  strokeWidth = "1.5",
}): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12.811 12.811">
    <g id="Group_3101" data-name="Group 3101" transform="translate(-1390.795 -124.094)">
      <path
        id="Path_145"
        data-name="Path 145"
        d="M818.662,7758.159l-10.69,10.69"
        transform="translate(583.883 -7633.004)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
      />
      <path
        id="Path_146"
        data-name="Path 146"
        d="M807.973,7758.159l10.69,10.69"
        transform="translate(583.883 -7633.004)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
      />
    </g>
  </svg>
)

export const CloseIconGrey: React.FC<{ width?: string; height?: string; color?: string; strokeWidth?: string }> = ({
  width = "10.7px",
  height = "10.7px",
  color = theme.palette.grey[800],
}): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 27 27" fill="none">
    <path
      d="M20.587 6.42332C20.1482 5.98457 19.4395 5.98457 19.0007 6.42332L13.4995 11.9133L7.99823 6.41207C7.55948 5.97332 6.85073 5.97332 6.41198 6.41207C5.97323 6.85082 5.97323 7.55957 6.41198 7.99832L11.9132 13.4996L6.41198 19.0008C5.97323 19.4396 5.97323 20.1483 6.41198 20.5871C6.85073 21.0258 7.55948 21.0258 7.99823 20.5871L13.4995 15.0858L19.0007 20.5871C19.4395 21.0258 20.1482 21.0258 20.587 20.5871C21.0257 20.1483 21.0257 19.4396 20.587 19.0008L15.0857 13.4996L20.587 7.99832C21.0145 7.57082 21.0145 6.85082 20.587 6.42332Z"
      fill={theme.palette.grey.A200}
    />
  </svg>
)

export const CloseCircleIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16.667" height="16.667" viewBox="0 0 16.667 16.667">
    <path
      data-name="Close Circle"
      d="M10 1.667A8.333 8.333 0 1 0 18.334 10 8.326 8.326 0 0 0 10 1.667zm0 15A6.667 6.667 0 1 1 16.667 10 6.676 6.676 0 0 1 10 16.667zm2.992-10.833L10 8.825 7.009 5.834 5.834 7.009 8.825 10l-2.991 2.992 1.175 1.175L10 11.175l2.992 2.992 1.175-1.175L11.175 10l2.992-2.992z"
      transform="translate(-1.667 -1.667)"
      style={{ fill: theme.palette.error.main }}
    />
  </svg>
)

export const CloudIcon: React.FC<{ color?: string }> = ({ color = theme.palette.grey[900] }): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19.162" height="13.413" viewBox="0 0 19.162 13.413">
    <path
      data-name="Icon awesome-cloud"
      d="M16.1 8.076a2.878 2.878 0 0 0-2.683-3.91 2.86 2.86 0 0 0-1.6.485 4.791 4.791 0 0 0-8.943 2.39c0 .081 0 .162.006.243a4.313 4.313 0 0 0 1.431 8.38H15.33a3.832 3.832 0 0 0 .77-7.588z"
      transform="translate(0 -2.25)"
      fill={color}
    />
  </svg>
)

export const CloudIntegrationIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23.738" height="14.834" viewBox="0 0 23.738 14.834">
    <path
      data-name="Icon ionic-ios-cloud"
      d="M20.262 12.952A6.953 6.953 0 0 0 7.63 9.749 3.286 3.286 0 0 0 6.383 9.5a3.433 3.433 0 0 0-3.31 3.4A4.528 4.528 0 0 0 0 17.2a4.359 4.359 0 0 0 4.214 4.381h15.311a4.362 4.362 0 0 0 .737-8.631z"
      transform="translate(0 -6.75)"
      fill="currentColor"
    />
  </svg>
)

export const CollapseIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16.938" height="16.938" viewBox="0 0 16.938 16.938">
    <path
      id="Icon_material-fullscreen-exit"
      data-name="Icon material-fullscreen-exit"
      d="M7.5,20.809h3.63v3.63h2.42V18.389H7.5Zm3.63-9.679H7.5v2.42h6.049V7.5H11.13Zm7.259,13.309h2.42v-3.63h3.63v-2.42H18.389Zm2.42-13.309V7.5h-2.42v6.049h6.049V11.13Z"
      transform="translate(-7.5 -7.5)"
      fill={theme.palette.grey[800]}
    />
  </svg>
)

export const ConfigToolsIcon = ({ color = theme.palette.grey.A200 }) => (
  <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.151 4.4998C19.151 6.4298 17.581 7.9998 15.6511 7.9998C15.241 7.9998 14.851 7.9198 14.4811 7.7898L4.16101 18.0998C3.58102 18.6898 2.63101 18.6898 2.04102 18.0998C1.45102 17.5198 1.45102 16.5698 2.04102 15.9798L8.39101 9.6298L6.61102 7.8498C6.22102 8.2398 5.59102 8.2398 5.20102 7.8498L4.50102 7.1498V9.6898C4.50102 9.8798 4.42102 10.0598 4.29102 10.1898C4.01101 10.4698 3.57102 10.4698 3.29101 10.1898L0.751022 7.6498C0.471014 7.3698 0.471014 6.9298 0.751022 6.6498C0.881018 6.5098 1.06102 6.4398 1.25101 6.4398H3.79102L3.08101 5.7298C2.69101 5.3398 2.69101 4.7098 3.08101 4.3198L5.90101 1.4898C7.07101 0.319805 8.97102 0.319805 10.141 1.4898L8.02102 3.6098L8.73102 4.3198C9.12102 4.7098 9.12102 5.3398 8.73102 5.7298L10.511 7.5098L12.361 5.6598C12.2311 5.2998 12.151 4.9098 12.151 4.4998C12.151 2.5698 13.721 0.999797 15.6511 0.999797C16.231 0.999797 16.771 1.1598 17.251 1.4098L14.551 4.1098L16.0411 5.5998L18.7411 2.89981C18.991 3.37981 19.151 3.9198 19.151 4.4998ZM14.0511 11.0498L18.991 15.9898C19.5711 16.5698 19.5711 17.5198 18.991 18.1098C18.4111 18.6998 17.461 18.6998 16.8711 18.1098L11.9311 13.1698L14.0511 11.0498Z"
      fill={color}
    />
  </svg>
)

export const MonitorIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.75 3.75C9.75 4.16 9.41 4.5 9 4.5C8.59 4.5 8.25 4.16 8.25 3.75C8.25 3.34 8.59 3 9 3C9.41 3 9.75 3.34 9.75 3.75ZM18 2.25V12.75C18 13.99 16.99 15 15.75 15H9.75V16.5H13.5C13.91 16.5 14.25 16.84 14.25 17.25C14.25 17.66 13.91 18 13.5 18H4.5C4.09 18 3.75 17.66 3.75 17.25C3.75 16.84 4.09 16.5 4.5 16.5H8.25V15H2.25C1.01 15 0 13.99 0 12.75V2.25C0 1.01 1.01 0 2.25 0H15.75C16.99 0 18 1.01 18 2.25ZM16.5 12H1.5V12.75C1.5 13.16 1.84 13.5 2.25 13.5H15.75C16.17 13.5 16.5 13.16 16.5 12.75V12ZM16.5 2.25C16.5 1.84 16.17 1.5 15.75 1.5H2.25C1.84 1.5 1.5 1.84 1.5 2.25V10.5H16.5V2.25Z"
      fill={theme.palette.neutralDark[16]}
    />
  </svg>
)

export const CopyIcon = ({
  width = "11",
  height = "12.266",
  color = theme.palette.primary[600],
}: {
  width?: string
  height?: string
  color?: string
}): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 10.594 12.266">
    <path
      fill={color}
      d="M10.806 1.5H4.115A1.118 1.118 0 0 0 3 2.615v7.806h1.115V2.615h6.691zm1.673 2.23H6.345A1.118 1.118 0 0 0 5.23 4.845v7.806a1.118 1.118 0 0 0 1.115 1.115h6.133a1.118 1.118 0 0 0 1.115-1.115V4.845a1.118 1.118 0 0 0-1.114-1.115zm0 8.921H6.345V4.845h6.133z"
      transform="translate(-3 -1.5)"
    />
  </svg>
)

export const CubeIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12.473" height="12.473" viewBox="0 0 12.473 12.473">
    <g data-name="Icon ionic-md-cube">
      <path
        data-name="Path 3618"
        d="M24.562 11.82a.349.349 0 0 0-.142.028l-4.945 2.564a.7.7 0 0 0-.348.6v5.176a.336.336 0 0 0 .354.32.381.381 0 0 0 .17-.039.061.061 0 0 0 .014-.008l4.864-2.581h.008a.707.707 0 0 0 .381-.615V12.14a.337.337 0 0 0-.356-.32z"
        transform="translate(-12.443 -8.03)"
        style={{ fill: theme.palette.info.main }}
      />
      <path
        data-name="Path 3619"
        d="M14.755 4.658 9.7 2.384a1.6 1.6 0 0 0-.462-.134 1.571 1.571 0 0 0-.459.134L3.71 4.658s-.223.092-.223.264.231.32.231.32l5.165 2.724a.878.878 0 0 0 .7 0l5.162-2.726a.4.4 0 0 0 .209-.32c.007-.178-.199-.262-.199-.262z"
        transform="translate(-2.997 -2.25)"
        style={{ fill: theme.palette.info.main }}
      />
      <path
        data-name="Path 3620"
        d="M7.693 14.407 2.746 11.84a.391.391 0 0 0-.142-.028.337.337 0 0 0-.354.32v5.118a.707.707 0 0 0 .381.615h.006L7.5 20.449a.368.368 0 0 0 .184.047.339.339 0 0 0 .354-.32V15a.7.7 0 0 0-.345-.593z"
        transform="translate(-2.25 -8.026)"
        style={{ fill: theme.palette.info.main }}
      />
    </g>
  </svg>
)
export const ChartIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.03942 9.2002C2.44089 5.15763 5.85169 2.00039 9.9999 2.00039C14.1481 2.00039 17.5589 5.15763 17.9604 9.2002H13.9999C13.7189 9.2002 13.4585 9.34763 13.3139 9.5886L11.7437 12.2056L9.14268 5.70308C9.02981 5.42089 8.76676 5.22703 8.4638 5.20275C8.16084 5.17848 7.87028 5.32798 7.71391 5.5886L5.54695 9.2002H2.03942ZM2.03938 10.8002H5.9999C6.28091 10.8002 6.54132 10.6528 6.6859 10.4118L8.25611 7.79477L10.8571 14.2973C10.97 14.5795 11.233 14.7734 11.536 14.7976C11.839 14.8219 12.1295 14.6724 12.2859 14.4118L14.4529 10.8002H17.9604C17.5591 14.8429 14.1482 18.0004 9.9999 18.0004C5.85156 18.0004 2.44067 14.8429 2.03938 10.8002ZM0.399902 10.0004C0.399902 15.3023 4.69797 19.6004 9.9999 19.6004C15.3018 19.6004 19.5999 15.3023 19.5999 10.0004C19.5999 4.69846 15.3018 0.400391 9.9999 0.400391C4.69803 0.400391 0.400008 4.69855 0.399902 10.0004Z"
      fill={theme.palette.neutralDark[16]}
    />
  </svg>
)

export const CleanupAllowed = ({ color = theme.palette.content.secondary, width = 113, height = 113 }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Done-Circle">
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3438 56.25C21.3438 36.8337 37.0837 21.0938 56.5 21.0938C75.9163 21.0938 91.6562 36.8337 91.6562 56.25C91.6562 75.6663 75.9163 91.4062 56.5 91.4062C37.0837 91.4062 21.3438 75.6663 21.3438 56.25ZM56.5 14.0625C33.2005 14.0625 14.3125 32.9505 14.3125 56.25C14.3125 79.5495 33.2005 98.4375 56.5 98.4375C79.7995 98.4375 98.6875 79.5495 98.6875 56.25C98.6875 32.9505 79.7995 14.0625 56.5 14.0625ZM80.0797 44.6734C81.4526 43.3005 81.4526 41.0745 80.0797 39.7016C78.7067 38.3286 76.4808 38.3286 75.1078 39.7016L49.4688 65.3407L37.8922 53.7641C36.5192 52.3911 34.2933 52.3911 32.9203 53.7641C31.5474 55.137 31.5474 57.363 32.9203 58.7359L46.9828 72.7984C48.3558 74.1714 50.5817 74.1714 51.9547 72.7984L80.0797 44.6734Z"
        fill={color}
      />
    </g>
  </svg>
)
