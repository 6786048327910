import {
  ColDef,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridReadyEvent,
  IRowNode,
  IsRowSelectable,
  PaginationChangedEvent,
  RefreshServerSideParams,
  RowModelType,
  SelectionChangedEvent,
  SideBarDef,
} from "ag-grid-community"
import "ag-grid-enterprise"
import {
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
} from "ag-grid-enterprise"
import { AgGridReact } from "ag-grid-react"
import clsx from "clsx"
import { ENDivider } from "en-react/dist/src/components/Divider"
import { debounce } from "lodash"
import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react"
import ActivityLogsTimeFilter from "src/pages/ActivityLogs/ActivityLogsTimeFilter"
import { IconNameType } from "src/shared/components/Icons"
import { ChartsTimeIntervalFilterType } from "src/utils"
import { DATA_GRID_POLLING_INTERVAL } from "src/utils/constants"
import { useDataGridStyles } from "./DataGrid.styles"
import { BlankSlateProps, DataGridBlankSlate } from "./DataGridBlankSlate"
import { DataGridLoader } from "./DataGridLoader"
import DataGridNavigationBar from "./DataGridNavigationBar/DataGridNavigationBar"
import DataGridPopover from "./DataGridPopOver"

export const errorLoadingOverlayParams: BlankSlateProps = {
  iconName: "enWarningCircle" as IconNameType,
  slateHeading: "Error Loading Records",
  text: "Refresh to try again.",
}

const defaultNoRowsOverlayComponentParams = {
  iconName: "blankSlate" as IconNameType,
  slateHeading: "No Records",
}

export interface PopoverButtonType {
  name: string
  title?: string | ((rowData: any) => string)
  callback?: (name: string, rowData: any) => void
  disabled?: (rowData: any) => boolean
  variant?: "normal" | "separator" | ((rowData: any) => "normal")
  show?: (rowData: any) => boolean
  hide?: (rowData: any) => boolean
  tooltipText?: string
  disabledTooltipText?: string | ((rowData: any) => string)
  disabledTooltipTextPlacement?: "bottom" | "left" | "right" | "top"
  emptySlateElement?: JSX.Element
}

type DataGridProps = {
  label?: string
  onSearchCallBack?: (searchText: string) => void
  columnDefs: ColDef[]
  rowData?: any
  treeData?: boolean
  serverSideGroupSelect?: string
  serverSideGroupKey?: string | number
  autoGroupColumnDef?: ColDef
  onGridReady: (event: GridReadyEvent<any>) => void
  onFirstDataRendered?: (event: FirstDataRenderedEvent<any>) => void
  isExpandable?: boolean
  isSortable?: boolean
  expandableRowComponent?: (params: any) => JSX.Element
  detailRowAutoHeight?: boolean
  suppressContextMenu?: boolean
  isMultiSelect?: boolean
  showRowsSelected?: boolean
  onRowSelected?: (selectedRows: any) => void
  onRowDragEnd?: (event: any) => void
  onRowDragEnter?: (event: any) => void
  rowDragEntireRow?: boolean
  paginationAutoPageSize?: boolean
  pagination?: boolean
  showLoading?: boolean
  noRowsOverlayComponentParams?: BlankSlateProps
  numberOfRowsSelected?: number
  onActionCallBack?: () => void
  actionText?: string
  onDragUpCallBack?: () => void
  dragUpText?: string
  onSaveCallBack?: () => void
  saveText?: string
  onDragDownCallBack?: () => void
  dragDownText?: string
  cacheBlockSize?: number
  onBulkActionPopover?: {
    popOverList: PopoverButtonType[]
  }
  isRowSelectable?: IsRowSelectable<any>
  isSideBarHidden?: boolean
  containerHeight?: number // For Data Grid inside expanded view
  showNavbars?: boolean
  onRefreshCallBack?: () => void
  refreshInterval?: number
  hasFilters?: boolean
  applyCollapsibleWrapper?: boolean
  hasColumns?: boolean
  dynamicWidthAutoSize?: boolean
  className?: string
  getRowHeight?: (params: any) => number
  handleTimeFilter?: (event: any) => void
  timeFilterValue?: ChartsTimeIntervalFilterType
  setPageSize?: (size: number, pageNumber?: number) => void
  setOffSet?: (offset: number) => void
  rowModelType?: RowModelType
  setAllSelected?: React.Dispatch<React.SetStateAction<boolean>>
  sortingOrder?: ("asc" | "desc" | null)[]
  rowHeight?: number
  popOverList?: PopoverButtonType[]
  disableTimeFilter?: boolean
  groupByCallBack?: {
    options: { [key: string]: string }[]
    onChange?: (selectedGroups: { [key: string]: string }) => void
    configValue?: string
  }
  headerHeight?: number
  rowDragMultiRow?: boolean
  onFilterChanged?: (event: FilterChangedEvent) => void
  autoSizeStrategy?: SizeColumnsToFitGridStrategy | SizeColumnsToFitProvidedWidthStrategy | SizeColumnsToContentStrategy
}

const DataGrid: ForwardRefRenderFunction<AgGridReact, DataGridProps> = (props, ref) => {
  const {
    label = "Rows",
    onSearchCallBack,
    detailRowAutoHeight = true,
    suppressContextMenu = true,
    isExpandable = false,
    isSortable = true,
    onGridReady,
    onFirstDataRendered,
    columnDefs,
    rowData,
    treeData = false,
    serverSideGroupSelect,
    serverSideGroupKey,
    autoGroupColumnDef,
    expandableRowComponent,
    isMultiSelect = false,
    showRowsSelected = true,
    onRowSelected,
    paginationAutoPageSize,
    pagination = false,
    showLoading,
    noRowsOverlayComponentParams = defaultNoRowsOverlayComponentParams,
    numberOfRowsSelected,
    onActionCallBack,
    actionText = "",
    refreshInterval = DATA_GRID_POLLING_INTERVAL,
    disableTimeFilter = false,
    onRowDragEnd,
    onRowDragEnter,
    rowDragEntireRow = false,
    onDragUpCallBack,
    dragUpText = "",
    onSaveCallBack,
    saveText = "",
    onDragDownCallBack,
    dragDownText = "",
    onBulkActionPopover,
    isRowSelectable,
    isSideBarHidden = false,
    containerHeight, // For Data Grid inside expanded view
    showNavbars = false,
    onRefreshCallBack,
    hasFilters = true,
    applyCollapsibleWrapper = false,
    hasColumns = false,
    className = "",
    getRowHeight,
    handleTimeFilter,
    timeFilterValue,
    setPageSize,
    setOffSet,
    rowModelType = "clientSide",
    setAllSelected,
    sortingOrder = ["asc", "desc", null],
    cacheBlockSize = 10,
    rowHeight,
    popOverList,
    groupByCallBack,
    headerHeight,
    rowDragMultiRow = false,
    onFilterChanged,
    autoSizeStrategy,
  } = props

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      sortable: isSortable,
      resizable: true,
      suppressMovable: true,
      suppressMenu: true,
      cellClass: "ag-text-cell",
    }
  }, [isSortable])

  const [serverSideRefreshing, setServerSideRefreshing] = useState(false)
  const [polling, setPolling] = useState(false)

  const pollTimerRef = useRef<NodeJS.Timeout>()

  const gridRef = useRef<AgGridReact>(null)

  useImperativeHandle(ref, () => {
    if (gridRef.current?.api) {
      const newObject = Object.create(Object.getPrototypeOf(gridRef.current.api))

      return {
        ...gridRef.current,
        api: Object.assign(newObject || {}, {
          ...gridRef.current.api,
          refreshServerSide: (params?: RefreshServerSideParams | undefined) => {
            setServerSideRefreshing(true)
            gridRef.current?.api.refreshServerSide(params)
          },
          showLoadingOverlay: () => {
            if (!polling) {
              gridRef.current?.api.showLoadingOverlay()
            }
          },
        }),
      } as AgGridReact
    } else {
      return gridRef.current as AgGridReact
    }
  })
  const classes = useDataGridStyles()
  const [expandedRow, setExpandedRow] = useState<string | null>(null)

  const onSelectionChanged = useCallback((e: SelectionChangedEvent<any>) => {
    if (setAllSelected) {
      if (e.source === "uiSelectAll") {
        setAllSelected?.((prev) => {
          if (prev === false) {
            const nodesToSelect: IRowNode[] = []
            gridRef.current!.api.forEachNode((node) => nodesToSelect.push(node))
            gridRef.current!.api.setNodesSelected({ nodes: nodesToSelect, newValue: true })
            onRowSelected?.(gridRef.current!.api.getSelectedRows())
            return !prev
          } else {
            const nodesToSelect: IRowNode[] = []
            gridRef.current!.api.forEachNode((node) => nodesToSelect.push(node))
            gridRef.current!.api.setNodesSelected({ nodes: nodesToSelect, newValue: false })
            onRowSelected?.(gridRef.current!.api.getSelectedRows())
            return !prev
          }
        })
      } else {
        if (e.source === "checkboxSelected") {
          setAllSelected?.((prev) => {
            if (prev) return !prev
            else return prev
          })
        }
        const selectedRows = gridRef.current!.api.getSelectedRows()
        return onRowSelected?.(selectedRows)
      }
    } else {
      const selectedRows = gridRef.current!.api.getSelectedRows()
      return onRowSelected?.(selectedRows)
    }
  }, [])

  useEffect(() => {
    if (showLoading) {
      setTimeout(() => {
        gridRef?.current?.api?.showLoadingOverlay()
      }, 0)
    } else if (!showLoading && rowData && rowData?.length < 1) {
      setTimeout(() => {
        gridRef?.current?.api?.showNoRowsOverlay()
      }, 0)
    } else {
      setTimeout(() => {
        gridRef?.current?.api?.hideOverlay()
      }, 0)
    }
  }, [showLoading, rowData])

  const sideBar = useMemo<SideBarDef | string | string[] | boolean | null>(() => {
    return {
      toolPanels: [
        ...(hasColumns
          ? [
              {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel",
                toolPanelParams: {
                  suppressRowGroups: true,
                  suppressValues: true,
                  suppressPivots: true,
                  suppressPivotMode: true,
                  suppressColumnFilter: true,
                  suppressColumnSelectAll: true,
                  suppressColumnExpandAll: true,
                },
              },
            ]
          : []),
        ...(hasFilters
          ? [
              {
                id: "filters",
                labelDefault: "Filters",
                labelKey: "filters",
                iconKey: "filter",
                toolPanel: "agFiltersToolPanel",
                toolPanelParams: {
                  suppressExpandAll: false,
                  suppressFilterSearch: true,
                },
              },
            ]
          : []),
      ],
      defaultToolPanel: undefined,
      hiddenByDefault: isSideBarHidden,
    }
  }, [isSideBarHidden])

  const debouncedSearchHandler = onSearchCallBack && debounce(onSearchCallBack, 1000)

  const updatedColumnDefinition = useMemo(() => {
    return [
      ...(isExpandable
        ? [
            {
              cellRenderer: "agGroupCellRenderer",
              maxWidth: 50,
              minWidth: 40,
              headerClass: "custom-ag-header",
              suppressColumnsToolPanel: true,
              suppressMovable: true,
              suppressMenu: true,
              lockPosition: true,
            },
          ]
        : []),
      ...columnDefs,
      ...(popOverList
        ? [
            {
              headerName: "",
              maxWidth: 50,
              minWidth: 50,
              cellRendererParams: { popOverList },
              cellRenderer: DataGridPopover,
              suppressColumnsToolPanel: true,
              headerClass: "custom-ag-header",
              cellStyle: { display: "flex", justifyContent: "right", alignItems: "center" },
              cellClass: "ag-select-text-cell",
              pinned: "right",
            } as ColDef,
          ]
        : []),
    ]
  }, [columnDefs, isExpandable, popOverList])

  // --------------------------- POLLING --------------------------------------------------------------------- //

  const handleStoreRefreshed = () => {
    pollTimerRef.current = undefined
    setServerSideRefreshing(false)
    setPolling(false)
  }

  useEffect(() => {
    if (!refreshInterval || rowModelType !== "serverSide") {
      clearTimeout(pollTimerRef.current)
      return
    }
    if (!polling && !pollTimerRef.current) {
      pollTimerRef.current = setTimeout(() => {
        setServerSideRefreshing(true)
        setPolling(true)
        gridRef.current?.api.refreshServerSide({ purge: false, route: [] })
      }, refreshInterval)
    }
  }, [polling, refreshInterval])
  // --------------------------- POLLING --------------------------------------------------------------------- //

  const onPaginationChanged = useCallback((params: PaginationChangedEvent<any>) => {
    setOffSet?.(params?.api?.paginationGetCurrentPage() * params?.api?.paginationGetPageSize())
    setPageSize?.(params?.api?.paginationGetPageSize(), params?.api?.paginationGetCurrentPage())
    setAllSelected?.((prev) => {
      if (prev === true) {
        const nodesToSelect: IRowNode[] = []
        params?.api?.forEachNode((node) => nodesToSelect.push(node))
        params?.api?.setNodesSelected({ nodes: nodesToSelect, newValue: true })
        onRowSelected?.(params?.api?.getSelectedRows())
      }
      return prev
    })
  }, [])

  return (
    <div className={clsx(classes.root, className)}>
      <>
        {showNavbars && (
          <DataGridNavigationBar
            onSearchCallBack={onSearchCallBack}
            debouncedSearchHandler={debouncedSearchHandler}
            showRowsSelected={showRowsSelected}
            numberOfRowsSelected={numberOfRowsSelected}
            label={label}
            onActionCallBack={onActionCallBack}
            actionText={actionText}
            onDragUpCallBack={onDragUpCallBack}
            dragUpText={dragUpText}
            onSaveCallBack={onSaveCallBack}
            saveText={saveText}
            onDragDownCallBack={onDragDownCallBack}
            dragDownText={dragDownText}
            onBulkActionPopover={onBulkActionPopover}
            serverSideRefreshing={serverSideRefreshing}
            onRefreshCallBack={onRefreshCallBack}
            refreshIntervalSeconds={refreshInterval / 1000}
            groupByCallBack={groupByCallBack}
          />
        )}
      </>
      {handleTimeFilter && timeFilterValue && (
        <>
          <ENDivider />
          <div className={classes.timefilterWrapper}>
            <ActivityLogsTimeFilter
              onChange={handleTimeFilter}
              value={timeFilterValue}
              isDisabled={disableTimeFilter}
            />
          </div>
        </>
      )}
      <div className={classes.agGridContainer} style={containerHeight ? { height: containerHeight } : {}}>
        <AgGridReact
          getRowId={(params) => params.data.id} // Note: Always make sure that there is `id` as unique identifier in each row object
          defaultColDef={defaultColDef}
          ref={gridRef}
          className="ag-theme-alpine-dark"
          rowData={rowData}
          treeData={treeData}
          isServerSideGroup={serverSideGroupSelect ? (dataItem: any) => dataItem[serverSideGroupSelect] : undefined}
          getServerSideGroupKey={serverSideGroupKey ? (dataItem: any) => dataItem[serverSideGroupKey] : undefined}
          autoGroupColumnDef={autoGroupColumnDef}
          sortingOrder={sortingOrder}
          accentedSort={true}
          columnDefs={updatedColumnDefinition}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          masterDetail={isExpandable}
          detailCellRenderer={useCallback(
            (params: any) => {
              setExpandedRow(params.data.id)
              if (expandedRow && expandedRow !== params.data.id) {
                gridRef.current!.api.forEachNode((node: any) => {
                  if (node?.data?.id === expandedRow) {
                    node?.setExpanded(false)
                  }
                })
              }
              return (
                <div className={clsx({ [classes.expandableComponentWrapper]: applyCollapsibleWrapper })}>
                  {expandableRowComponent?.(params)}
                </div>
              )
            },
            [expandedRow],
          )}
          detailRowAutoHeight={detailRowAutoHeight}
          suppressContextMenu={suppressContextMenu}
          rowSelection={isMultiSelect ? "multiple" : "single"}
          onSelectionChanged={onSelectionChanged}
          paginationAutoPageSize={paginationAutoPageSize}
          pagination={pagination}
          suppressRowTransform={true}
          sideBar={sideBar}
          paginationPageSize={10}
          paginationPageSizeSelector={[10, 20, 50]}
          tooltipShowDelay={0}
          loadingOverlayComponent={DataGridLoader}
          noRowsOverlayComponent={DataGridBlankSlate}
          noRowsOverlayComponentParams={noRowsOverlayComponentParams}
          suppressRowClickSelection
          isRowSelectable={isRowSelectable}
          suppressCellFocus
          autoSizeStrategy={autoSizeStrategy}
          getRowHeight={getRowHeight}
          rowModelType={rowModelType}
          cacheBlockSize={cacheBlockSize}
          rowDragMultiRow={rowDragMultiRow}
          onRowDragEnd={onRowDragEnd}
          onRowDragEnter={onRowDragEnter}
          rowDragEntireRow={rowDragEntireRow}
          // enableCellTextSelection
          rowHeight={rowHeight}
          onPaginationChanged={onPaginationChanged}
          onStoreRefreshed={handleStoreRefreshed}
          headerHeight={headerHeight}
          onFilterChanged={onFilterChanged}
          suppressAnimationFrame={true}
        />
      </div>
    </div>
  )
}

export default forwardRef(DataGrid)
