import { createUseStyles } from "react-jss"

export const useProtocolCardStyles = createUseStyles((theme) => ({
  card: {
    width: 211,
    height: 163,
    backgroundColor: theme.palette.grey[400],
    position: "relative",
    borderRadius: 10,
    paddingTop: 36,
    "&:hover, &:active": {
      outline: `2px solid ${theme.palette.primary[600]}`,
      cursor: "pointer",
      boxShadow: "#00000029 0px 10px 36px 0px, #0000000f 0px 0px 0px 1px",
    },
  },
  selectedCard: {
    outline: `2px solid ${theme.palette.primary[600]}`,
  },
  settingsIconContainer: {
    position: "absolute",
    top: 12,
    right: 13,
  },
  text: {
    marginTop: 16,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  fipsCompliant: {
    margin: "auto",
    fontSize: 12,
    marginTop: 4,
    fontWeight: 600,
    borderRadius: 47,
    color: theme.palette.success[500],
    backgroundColor: theme.palette.success[400],
    width: 112,
  },
}))
