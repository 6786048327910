import { ENChip } from "en-react/dist/src/components/Chip"
import { ENListItem } from "en-react/dist/src/components/ListItem"
import { useCallback, useEffect, useMemo, useState } from "react"
import ReactCountryFlag from "react-country-flag"
import { COUNTRIES_LIST } from "src/constants"
import { GenericObject } from "src/utils/commonTypes"
import { OptionType } from "src/utils/utils.types"
import ZtnaButton from "../../ZtnaButton"
import ZtnaMultiSelect from "../EnZtnaMultiSelect/EnZtnaMultiSelect"
import { useFormComponentsStyles } from "../FormComponents.styles"
import { ZtnaChipMultiSelectInputProps } from "../FormComponents.types"

const COUNTRY_CODES = COUNTRIES_LIST.map((country) => country.value)

const commonFlagProps = (countryCode: string) => ({
  style: {
    width: 21,
    height: 13,
  },
  "data-testid": countryCode,
  countryCode: countryCode,
  svg: true,
})
const COUNTRY_CODES_SET = new Set(COUNTRY_CODES)

const ZtnaChipMultiSelect = ({
  id,
  value,
  onChange,
  CustomOptionProp,
  options,
  error,
  label,
  placeholder,
  disabled,
  isLoading,
  maxVisibleOptionsLimit = 5,
  onRemoveOption,
  objectName = "",
  objectCreationFunction = () => {},
  isOptionDisabled,
  closeMenuOnSelect = false,
  isSearchable = false,
  fieldNote = "",
}: ZtnaChipMultiSelectInputProps): JSX.Element => {
  const classes = useFormComponentsStyles()

  const selectedOptionsSet = new Set(value?.map((item: any) => item.value))

  const [showAllOptions, setShowAllOptions] = useState(false)

  const maxDisplayOptions = showAllOptions ? options.length : maxVisibleOptionsLimit

  const [availableOptions, setAvailableOptions] = useState<OptionType[]>(
    value?.length ? options.filter((option) => !selectedOptionsSet.has(option.value)) : options,
  )

  useEffect(() => {
    setAvailableOptions(value?.length ? options.filter((option) => !selectedOptionsSet.has(option.value)) : options)
  }, [options])

  const CustomOption = useCallback(
    ({ data }: { data: any }) => {
      return (
        <ENListItem
          key={data?.value}
          onClick={() => {
            if (!data?.value) {
              onChange?.([data])
              setAvailableOptions(options)
            } else {
              setAvailableOptions((prev) => prev?.filter((item) => item.value !== data?.value) || [])
              onChange?.([...(value as GenericObject[]), ...options?.filter((item) => item.value === data?.value)])
            }
          }}
          value={data?.value}
          isDisabled={(value as any)?.[0]?.value === "" || data.value === "no-option-available"}
        >
          <div className={classes.listItemContainer}>
            {COUNTRY_CODES_SET.has(data.value) && <ReactCountryFlag {...commonFlagProps(data.value)} />}
            {data?.label}
          </div>
        </ENListItem>
      )
    },
    [JSON.stringify(options), JSON.stringify(value)],
  )

  const chipsRender = useMemo(() => {
    return value
      ? [
          ...value?.slice(0, maxDisplayOptions).map((item: any, index: number) => (
            <ENChip
              variant="tertiary"
              key={`idx-${item.id}-chip`}
              {...(item?.isRecommended ? { isDismissible: undefined } : { isDismissible: true })}
              onClose={(e) => {
                if (!disabled) {
                  onRemoveOption(item, value)

                  setAvailableOptions(
                    options?.filter(
                      (opt) => value.some((val: any) => val.value !== opt.value) || opt.value === item.value,
                    ) || [],
                  )
                }
              }}
            >
              <div className={classes.chipContent}>
                {COUNTRY_CODES_SET.has(item.value) && <ReactCountryFlag {...commonFlagProps(item.value)} />}
                {item.label}
              </div>
            </ENChip>
          )),
        ]
      : []
  }, [JSON.stringify(value), JSON.stringify(options), maxDisplayOptions, disabled, onRemoveOption])

  return (
    <>
      <ZtnaMultiSelect
        label={label}
        id="en-ztna-addUserToUserGroup-multiselectInput"
        CustomOption={CustomOptionProp || CustomOption}
        onChange={(value) => {
          onChange?.(value)
        }}
        fieldNote={fieldNote}
        placeholder={placeholder}
        options={
          availableOptions.length === 0
            ? [{ label: "No Option Available", value: "no-option-available" }]
            : availableOptions
        }
        value={value}
        disabled={disabled}
        isLoading={isLoading}
        isSearchable={isSearchable}
        error={error}
      />
      <div className={classes.chipsContainer}>
        {chipsRender}
        {value?.length > maxVisibleOptionsLimit && (
          <ZtnaButton
            className={classes.buttonText}
            onClick={() => setShowAllOptions(!showAllOptions)}
            buttonType="tertiary"
            title={!showAllOptions ? `+${value?.length - maxVisibleOptionsLimit} more` : "Show less"}
          />
        )}
      </div>
    </>
  )
}

export default ZtnaChipMultiSelect
