export const XIQ_DOMAIN = "//dis1r1.qa.xcloudiq.com"
export const DEEP_LINK_PREFIX = "enztna://"

export const TENANT_ADMIN_BASE_URL = "/admin"
export const SETUP_WORKSPACE_URL = "/admin/auth/api/v1/workspaces/"

export const CATALOG_APPS = "/admin/auth/api/v1/accounts/catalog-apps"
export const XIQ_LOGOUT_URL = "/admin/auth/api/v1/accounts/oauth-logout"

// USERS
export const CUSTOMER_ADMIN_USERS = "/admin/auth/api/v1/accounts/customer-admin-users/"
export const USERS_TREE_CHART = (userId: number): string =>
  `/admin/auth/api/v1/accounts/customer-admin-users/${userId}/tree-chart/`

//USER GROUPS
export const GET_ACCESS_GROUP_USERS_URL = "/admin/resource/api/v1/access-group/associated-user-access-group/"
export const GET_USER_ASSOCIATED_USER_GROUPS = "/admin/resource/api/v1/access-group/user-associated-access-groups/"
export const ADD_USER_TO_USER_GROUP = "/admin/resource/api/v1/access-group/add-user"
export const GET_CUSTOMER_ADMIN_ACCESS_GROUPS = "/admin/resource/api/v1/access-group/"
export const GET_CUSTOMER_ADMIN_ACCESS_GROUPS_DETAILS = (groupId: string) =>
  `/admin/resource/api/v1/access-group/${groupId}/details/`
export const CUSTOMER_ADMIN_WORKSPACE_ACCESS_GROUPS = "/admin/resource/api/v1/access-group/workspace-access-groups/"
export const FILTER_ACCESS_GROUPS = "/admin/resource/api/v1/access-group/filter-by/"
export const GET_ACCESS_GROUP_ASSOCIATIONS = (accessGroupId: string): string =>
  `/admin/resource/api/v1/access-group/${accessGroupId}/associations/`
export const REMOVE_ACCESS_GROUP = (accessGroupId: string): string =>
  `/admin/resource/api/v1/access-group/${accessGroupId}/delete/`
export const END_SYSTEMS_ADD_TO_USER_GROUP = "/admin/resource/api/v1/access-group/add-bulk-users"
export const IDP_CLEANUP_AND_DISCONNECT = "/admin/resource/api/v1/access-group/idp-disconnect-cleanup/"

// DEVICES
export const GET_BULK_DEVICES_ASSOCIATIONS = `/admin/resource/api/v1/resources/devices/associations/`

export const SEARCH_DEVICES = "/admin/resource/api/v1/resources/devices/search-devices/"
export const MANAGE_DEVICES_ACCESS = "/admin/resource/api/v1/resources/devices/manage-access/"
export const GET_DEVICE_DETAILS = "/admin/resource/api/v1/resources/devices/device-details/"
export const GET_DEVICE_USERS_COUNT = "/admin/resource/api/v1/resources/devices/associated-users-count/"

export const DEVICES = (offset: number, limit: number) =>
  `/admin/resource/api/v1/resources/devices/?offset=${offset}&limit=${limit}`
export const REMOVE_DEVICE = "/admin/resource/api/v1/resources/devices/"
export const ADD_DEVICES = "/admin/resource/api/v1/resources/devices/"
export const IMPORT_DEVICES = "/admin/resource/api/v1/resources/devices/import-devices/"
export const VALIDATE_FILE_CONTENT = "/admin/resource/api/v1/resources/devices/validate-file-content/"
export const DEVICE_GROUPS_DROPDOWN = "/admin/resource/api/v1/resources/device-groups/drop-down/"
export const DEVICES_FILTER = "/admin/resource/api/v1/resources/devices/filter-by/"

// DEVICE GROUPS
export const GET_DEVICE_GROUPS = "/admin/resource/api/v1/resources/device-groups/"
export const GET_DEVICE_GROUPS_DROPDOWN = "/admin/resource/api/v1/resources/device-groups/drop-down/"
export const FILTER_DEVICE_GROUPS = "/admin/resource/api/v1/resources/device-groups/filter-by/"
export const GET_DEVICE_GROUP_ASSOCIATIONS = (deviceGroupId: string): string =>
  `/admin/resource/api/v1/resources/device-groups/${deviceGroupId}/associations/`
export const GET_DEVICE_GROUP_DETAILS = (deviceGroupId: string): string =>
  `/admin/resource/api/v1/resources/device-groups/${deviceGroupId}/details/`
export const REMOVE_DEVICE_GROUP = (deviceGroupId: string): string =>
  `/admin/resource/api/v1/resources/device-groups/${deviceGroupId}/`
export const END_SYSTEMS_ADD_TO_DEVICE_GROUP = "/admin/resource/api/v1/resources/device-groups/devices/"

// SITES
export const DISCOVERY_COUNTRIES_LIST = "/admin/resource/api/v1/resources/sites/countries/"
export const CUSTOMER_ADMIN_SITES = "/admin/resource/api/v1/resources/sites/"
export const CUSTOMER_ADMIN_SERVICE_CONNECTORS_AGAINST_SITE = (site: string): string =>
  `/admin/resource/api/v1/resources/sites/${site}/hosts/`
export const SITES_TREE_CHART = (siteId: string): string =>
  `/admin/resource/api/v1/resources/sites/${siteId}/tree-chart/`
export const GET_SITE_ASSOCIATIONS = (siteId: string): string =>
  `/admin/resource/api/v1/resources/sites/${siteId}/associations/`
export const REMOVE_SITE = (siteId: string): string => `/admin/resource/api/v1/resources/sites/${siteId}/`
export const SITE_TREE = "/site/v1/tree"
export const SITE_GROUPS = "/site/v1/site-groups"
export const ADD_EDIT_SITES = "/site/v1/sites"

// SERVICE CONNECTORS
export const CUSTOMER_ADMIN_HOSTS = "/admin/resource/api/v1/resources/hosts/"
export const CUSTOMER_ADMIN_HOSTS_EXPANDED_VIEW = (hostId: string) =>
  `/admin/resource/api/v1/resources/hosts/${hostId}/expanded-view/`
export const CUSTOMER_ADMIN_HOSTS_FAILED_COMMAND = `/admin/resource/api/v1/resources/hosts/instances/upgrade/command/`
export const CUSTOMER_ADMIN_PRIVATE_HOST_ACCESS_TOKEN = "/admin/resource/api/v1/resources/hosts/generate-token/"
export const CUSTOMER_ADMIN_PRIVATE_HOST_SCALE_INSTANCE_TOKEN = (hostId: string) =>
  `/admin/resource/api/v1/resources/hosts/${hostId}/token/`
export const GET_AWS_LOAD_BALANCERS = (hostId: string): string =>
  `/admin/resource/api/v1/resources/hosts/${hostId}/aws-load-balancers/`
export const GET_AZURE_LOAD_BALANCERS = (hostId: string): string =>
  `/admin/resource/api/v1/resources/hosts/${hostId}/azure-load-balancers/`
export const GET_GCP_LOAD_BALANCERS = (hostId: string): string =>
  `/admin/resource/api/v1/resources/hosts/${hostId}/gcp-load-balancers/`
export const UPDATE_CONNECTOR = (hostId: string): string => `/admin/resource/api/v1/resources/hosts/${hostId}/`
export const GET_HOST_ASSOCIATIONS = (hostId: string): string =>
  `/admin/resource/api/v1/resources/hosts/${hostId}/associations/`
export const REMOVE_SERVICE_CONNECTOR = (hostId: string): string => `/admin/resource/api/v1/resources/hosts/${hostId}/`
export const REMOVE_AND_ENABLE_DISABLE_SERVICE_CONNECTOR_INSTANCE = (instanceId: string): string =>
  `/admin/resource/api/v1/resources/hosts/instances/${instanceId}/`
export const FORCE_REMOVE_CONNECTOR = (id: string): string => `/admin/resource/api/v1/resources/hosts/hard-delete/${id}`
export const FORCE_REMOTE_INSTANCE = (id: string): string =>
  `/admin/resource/api/v1/resources/hosts/instances/${id}/hard-delete/`
export const UPGRADE_SERVICE_CONNECTORS = "/admin/resource/api/v1/resources/hosts/instances/upgrade/"
export const RETRY_SERVICE_CONNECTORS_UPGRADE = "/admin/resource/api/v1/resources/hosts/instances/upgrade/retry/"

// APPLICATIONS
export const CUSTOMER_ADMIN_SERVICES = "/admin/resource/api/v1/resources/services/"
export const CUSTOMER_ADMIN_SERVICES_FILTER = "/admin/resource/api/v1/resources/services/filter-by/"
export const END_USER_SERVICES = "/resource/api/v1/resources/services/"

export const CUSTOMER_ADMIN_PUBLIC_SAAS_APPLICATIONS = (protocol: string) =>
  `/admin/resource/api/v1/resources/services/?type=${protocol}`
export const SERVICES_TREE_CHART = (serviceId: string): string =>
  `/admin/resource/api/v1/resources/services/${serviceId}/tree-chart/`
export const FILTER_ALL_TYPE_SERVICES = "/admin/resource/api/v1/resources/services/filter-by/"

export const GET_SAAS_APPLICATIONS = "/admin/resource/api/v1/resources/services/saas/"
export const GET_AGENTLESS_SERVICE_ASSOCIATIONS = (serviceId: string): string =>
  `/admin/resource/api/v1/resources/services/${serviceId}/associations/`

export const REMOVE_AGENTLESS_SERVICE = (serviceId: string): string =>
  `/admin/resource/api/v1/resources/services/${serviceId}/`
export const FORCE_REMOVE_SERVICE = (id: string): string =>
  `/admin/resource/api/v1/resources/services/hard-delete/${id}`
export const SEARCH_APPLICATIONS = "/admin/resource/api/v1/resources/services/search/"

// APPLICATION GROUPS
export const CUSTOMER_ADMIN_PROJECTS = "/admin/resource/api/v1/resources/projects/"
export const CUSTOMER_ADMIN_PROJECTS_FILTERS = "/admin/resource/api/v1/resources/projects/filter-by/"
export const GET_CUSTOMER_ADMIN_PROJECTS_DETAILS = (projectId: string) =>
  `/admin/resource/api/v1/resources/projects/${projectId}/details/`
export const GET_PROJECT_ASSOCIATIONS = (projectId: string): string =>
  `/admin/resource/api/v1/resources/projects/${projectId}/associations/`
export const REMOVE_PROJECT = (projectId: string): string =>
  `/admin/resource/api/v1/resources/projects/${projectId}/hard-deletion/`
export const APPLICATION_GROUPS_DROPDOWN = "/admin/resource/api/v1/resources/projects/drop-down/"

// NETWORKS
export const NETWORKS = "/admin/resource/api/v1/resources/networks/"
export const NETWORKS_FILTER = "/admin/resource/api/v1/resources/networks/filter-by/"

// NETWORK GROUPS
export const NETWORK_GROUPS = "/admin/resource/api/v1/resources/network-groups/"
export const NETWORK_GROUPS_FILTER = "/admin/resource/api/v1/resources/network-groups/filter-by/"
export const GET_NETWORK_GROUPS_DETAILS = (networkGroupId: string) =>
  `/admin/resource/api/v1/resources/network-groups/${networkGroupId}/details/`
export const NETWORK_GROUPS_DROPDOWN = "/admin/resource/api/v1/resources/network-groups/drop-down/"

// POLICIES
export const CUSTOMER_ADMIN_CONDITIONS = "/admin/resource/api/v1/policies/conditions/"
export const CUSTOMER_ADMIN_CONDITIONS_FILTER = "/admin/resource/api/v1/policies/conditions/filter-by/"
export const CUSTOMER_ADMIN_CONDITIONS_DROPDOWN = (type: "AUTHENTICATION" | "TIME" | "LOCATION") =>
  `/admin/resource/api/v1/policies/conditions/drop-down/?type=${type}`
export const CUSTOMER_ADMIN_CONDITIONS_ASSOCIATIONS = (id: string) =>
  `/admin/resource/api/v1/policies/conditions/${id}/associations/`

export const CUSTOMER_ADMIN_SSID = `/admin/resource/api/v1/resources/ssids/?is_managed=true`
export const XIQ_PROFILES = "/admin/resource/api/v1/policies/xiq-user-profiles/"

export const CUSTOMER_ADMIN_POLICIES = "/admin/resource/api/v1/policies/"
export const POLICY_REORDER = "/admin/resource/api/v1/policies/reorder/"
export const CUSTOMER_ADMIN_POLICIES_VLAN = "/admin/resource/api/v1/policies/vlan/"
export const CUSTOMER_ADMIN_POLICIES_EXPANDED_VIEW = (id: string) =>
  `/admin/resource/api/v1/policies/${id}/expanded-view/`

export const FILTER_POLICIES = "/admin/resource/api/v1/policies/filter-by/"
export const ALLOWED_OS_AND_BROWSER_FOR_END_USER_SERVICE =
  "/admin/resource/api/v1/policies/service-allowed-device-postures/"
export const ALLOWED_LOCATIONS_FOR_END_USER_SERVICE = "/admin/resource/api/v1/policies/service-allowed-countries/"
export const REMOVE_POLICY = "/admin/resource/api/v1/policies/"
export const FORCE_REMOVE_POLICY = (policyId: string): string =>
  `/admin/resource/api/v1/policies/${policyId}/hard-delete`
export const SYNCED_POLICIES = "/admin/resource/api/v1/policies/synced-user-groups/"

// PUBLIC CLOUD
export const AWS_KEY_PAIR_AGAINST_SITE = (vpcId: string): string =>
  `/admin/resource/api/v1/cloud-catalogue/aws-cloud/vpc/${vpcId}/key-pairs/`
export const AZURE_KEY_PAIR_AGAINST_SITE = (vnetId: string): string =>
  `/admin/resource/api/v1/cloud-catalogue/azure-cloud/vnet/${vnetId}/key-pairs/`
export const GCP_KEY_PAIR_AGAINST_SITE = (vpcId: string): string =>
  `/admin/resource/api/v1/cloud-catalogue/gcp-cloud/vpc/${vpcId}/key-pairs/`

export const CUSTOMER_ADMIN_SITE_VPC_SUBNET_IDS = (vpcId: string): string =>
  `/admin/resource/api/v1/cloud-catalogue/aws-cloud/vpc/${vpcId}/subnet/ids`
export const CUSTOMER_ADMIN_GCP_SUBNETS = (vpcId: string, regionId: string): string =>
  `/admin/resource/api/v1/cloud-catalogue/gcp-cloud/vpc/${vpcId}/region/${regionId}/subnets/`
export const CUSTOMER_ADMIN_SITE_VNET_SUBNET_IDS = (vnetId: string): string =>
  `/admin/resource/api/v1/cloud-catalogue/azure-cloud/${vnetId}/subnets/`
export const CUSTOMER_ADMIN_AWS_INSTANCE_TYPE = "/admin/resource/api/v1/cloud-catalogue/aws-cloud/vpc/instance/type"
export const CUSTOMER_ADMIN_AZURE_INSTANCE_TYPE = "/admin/resource/api/v1/cloud-catalogue/azure-cloud/instance/type/"
export const CUSTOMER_ADMIN_GCP_INSTANCE_TYPE = (zoneId: string) =>
  `/admin/resource/api/v1/cloud-catalogue/gcp-cloud/zone/${zoneId}/instance-type/`

export const CUSTOMER_ADMIN_CLOUD_CATALOGUE_AWS = "/admin/resource/api/v1/cloud-catalogue/aws-cloud/"
export const CUSTOMER_ADMIN_CLOUD_CATALOGUE_SPIN_UP_STATUS = "/admin/resource/api/v1/cloud-catalogue/spin-up/status/"

export const CUSTOMER_ADMIN_CLOUD_CATALOGUE_AWS_CLOUD =
  "/admin/resource/api/v1/cloud-catalogue/aws-cloud/multi-cloud-host/spin-up/"
export const CUSTOMER_ADMIN_CLOUD_CATALOGUE_AZURE_CONNECTOR =
  "/admin/resource/api/v1/cloud-catalogue/azure-cloud/multi-cloud-host/spin-up/"
export const CUSTOMER_ADMIN_CLOUD_CATALOGUE_GCP_CONNECTOR =
  "/admin/resource/api/v1/cloud-catalogue/gcp-cloud/multi-cloud-host/spin-up/"
export const CUSTOMER_ADMIN_CLOUD_CATALOGUE_AWS_LOAD_BALANCER =
  "/admin/resource/api/v1/cloud-catalogue/aws-cloud/load-balancer/in-bulk/"
export const CUSTOMER_ADMIN_CLOUD_CATALOGUE_AZURE_LOAD_BALANCER =
  "/admin/resource/api/v1/cloud-catalogue/azure-cloud/load-balancer/in-bulk/"
export const CUSTOMER_ADMIN_CLOUD_CATALOGUE_GCP_LOAD_BALANCER =
  "/admin/resource/api/v1/cloud-catalogue/gcp-cloud/load-balancer/in-bulk/"
export const CUSTOMER_ADMIN_AZURE_CLOUD = "/admin/resource/api/v1/cloud-catalogue/azure-cloud/"
export const TENANT_VALIDATE_WORKSPACE = "/admin/auth/api/v1/workspaces/validate-vhm-license"

export const CUSTOMER_ADMIN_GCP = "/admin/resource/api/v1/cloud-catalogue/gcp-cloud/"

export const CUSTOMER_ADMIN_AZURE_CLOUD_VALIDATE_ACCOUNT =
  "/admin/resource/api/v1/cloud-catalogue/azure-cloud/validate-azure-account/"
export const CUSTOMER_ADMIN_GCP_VALIDATE_ACCOUNT = "/admin/resource/api/v1/cloud-catalogue/gcp-cloud/validate-account/"
export const CUSTOMER_ADMIN_AWS_CLOUD = "/admin/resource/api/v1/cloud-catalogue/aws-cloud/"

export const CUSTOMER_ADMIN_GCP_CLOUD = "/admin/resource/api/v1/cloud-catalogue/gcp-cloud/"

export const CUSTOMER_ADMIN_AWS_VPC_BY_REGION = "/admin/resource/api/v1/cloud-catalogue/aws-cloud/aws-vpc-by-region/"
export const CUSTOMER_ADMIN_AZURE_TREE_CHART = "/admin/resource/api/v1/cloud-catalogue/azure-cloud/tree-chart/"
export const CUSTOMER_ADMIN_GCP_TREE_CHART = "/admin/resource/api/v1/cloud-catalogue/gcp-cloud/tree-chart/"

export const CUSTOMER_ADMIN_CLOUD_CATALOGUE_AWS_ACCOUNT_LIST =
  "/admin/resource/api/v1/cloud-catalogue/aws-cloud/aws-account-id-list/"
export const CUSTOMER_ADMIN_CLOUD_CATALOGUE_AZURE_ACCOUNT_LIST =
  "/admin/resource/api/v1/cloud-catalogue/azure-cloud/account/"
export const CUSTOMER_ADMIN_CLOUD_CATALOGUE_GCP_ACCOUNT_LIST =
  "/admin/resource/api/v1/cloud-catalogue/gcp-cloud/accounts/"
export const CUSTOMER_ADMIN_CLOUD_CATALOGUE_AZURE_RESOURCE_GROUPS =
  "/admin/resource/api/v1/cloud-catalogue/azure-cloud/resource-group-names/"
export const CUSTOMER_ADMIN_CLOUD_CATALOGUE_AZURE_VNETS =
  "/admin/resource/api/v1/cloud-catalogue/azure-cloud/vnet-names/"
export const CUSTOMER_ADMIN_CLOUD_CATALOGUE_AZURE_REGIONS =
  "/admin/resource/api/v1/cloud-catalogue/azure-cloud/region-names/"
export const CUSTOMER_ADMIN_CLOUD_CATALOGUE_AWS_VPC_ID_LIST =
  "/admin/resource/api/v1/cloud-catalogue/aws-cloud/aws-vpc-id-list/"

export const GET_AWS_SUBSCRIPTION_ASSOCIATIONS = (integrationId: string): string =>
  `/admin/resource/api/v1/cloud-catalogue/aws-cloud/${integrationId}/associations/`
export const GET_AZURE_SUBSCRIPTION_ASSOCIATIONS = (integrationId: string): string =>
  `/admin/resource/api/v1/cloud-catalogue/azure-cloud/${integrationId}/associations/`
export const GET_GCP_SUBSCRIPTION_ASSOCIATIONS = (integrationId: string): string =>
  `/admin/resource/api/v1/cloud-catalogue/gcp-cloud/${integrationId}/associations/`
export const GET_GCP_SUBSCRIPTION_VPC = (projectId: string): string =>
  `/admin/resource/api/v1/cloud-catalogue/gcp-cloud/${projectId}/vpcs/`
export const GET_GCP_SITE_REGIONS = (vpcId: string): string =>
  `/admin/resource/api/v1/cloud-catalogue/gcp-cloud/vpc/${vpcId}/regions/`
export const GET_GCP_ZONES = (regionId: string): string =>
  `/admin/resource/api/v1/cloud-catalogue/gcp-cloud/region/${regionId}/zones/`

export const FORCE_REMOVE_AZURE_CLOUD = (id: string): string =>
  `/admin/resource/api/v1/cloud-catalogue/azure-cloud/hard-delete/${id}/`
export const FORCE_REMOVE_AWS_CLOUD = (id: string): string =>
  `/admin/resource/api/v1/cloud-catalogue/aws-cloud/hard-delete/${id}/`
export const FORCE_REMOVE_GCP_CLOUD = (id: string): string => `/admin/resource/api/v1/cloud-catalogue/gcp-cloud/${id}/`

// EVENT COLLECTORS
export const ADD_SPLUNK = "/admin/resource/api/v1/event-collectors/splunk/"
export const VALIDATE_SPLUNK = "/admin/resource/api/v1/event-collectors/splunk/verification/"
export const UPDATE_SPLUNK = (splunkId: string): string => `/admin/resource/api/v1/event-collectors/splunk/${splunkId}/`
export const GET_SPLUNK_EVENT = "/admin/resource/api/v1/event-collectors/splunk/"
export const DELETE_SPLUNK = (splunkId: string) => `/admin/resource/api/v1/event-collectors/splunk/${splunkId}/`

export const REQUEST_API_BASED_LOGS_TOKEN = "/admin/auth/api/v1/api-tokens/workspace/"

export const MOBILE_DEVICE_MANAGEMENT = "/admin/resource/api/v1/mdm/"
export const MDM_FORCE_SYNC = "/admin/resource/api/v1/mdm/force-sync/"
export const MDM_DEVICES = "/admin/resource/api/v1/mdm/devices/"

export const VALIDATE_MDM = `${MOBILE_DEVICE_MANAGEMENT}validate/`
export const MDM_SETTINGS = `${MOBILE_DEVICE_MANAGEMENT}settings/`

// DNS Policy API Endpoints

export const DNS_POLICIES = "/admin/resource/api/v1/policies/dns/"
export const DNS_POLICIES_FILTER = "/admin/resource/api/v1/policies/dns/filter-by/"
export const REMOVE_DNS_POLICIES = (dnsPolicyId: string): string =>
  `/admin/resource/api/v1/policies/dns/${dnsPolicyId}/`
export const DNS_SERVER_PROTOCOL = (protocol: string | string[]): string => {
  // Check if protocol is an array and join it into a string if true, else use it as is
  const protocolParam = Array.isArray(protocol) ? protocol.join(",") : protocol
  return `/admin/resource/api/v1/resources/workspace/services/?protocol=${protocolParam}`
}

export const DNS_SERVER_ASSOCIATION = (serverId: string): string =>
  `/admin/resource/api/v1/resources/services/${serverId}/dns/associations/`

// DNS Policy API Endpoints

export const ACTIVE_USER_DEVICE_IDENTITIES = "/admin/auth/api/v1/workspaces/licensing-identities-count/"

/*---------------------------------- */
export const SEARCH_ACCESS_GROUP_USERS_URL = "/admin/auth/api/v1/accounts/search-users/"
export const CUSTOMER_ADMIN_HOSTS_AVAILABILITY_STATUS_COUNT = "/admin/analytics/api/v1/hosts/availability/status"
export const CUSTOMER_ADMIN_SERVICES_CONNECTION_STATUS = "/admin/analytics/api/v1/services/availability/status"

export const CUSTOMER_ADMIN_FILTER_USERS = "/admin/auth/api/v1/accounts/users/filter-by/"

export const CUSTOMER_ADMIN_SERVICES_PROTOCOL_DISTRIBUTION =
  "/admin/resource/api/v1/analytics/services/protocol/distribution/"
export const CUSTOMER_ADMIN_POLICIES_STATS = "/admin/resource/api/v1/analytics/policies/count/"
export const VALIDATE_IMPORT_USERS_COLUMN = "/admin/auth/api/v1/accounts/validate-file-content/"
export const IMPORT_USERS = "/admin/auth/api/v1/accounts/import-users/"
export const SERVICE_CONNECTOR_NOTIFICATIONS = "/admin/resource/api/v1/release/notifications/host/"
export const SAML_SETTINGS = "/admin/auth/api/v1/saml/settings/"

export const APPLICATION_USAGE_ANALYTICS = "/admin/analytics/api/v1/services/usage/"
export const ACCOUNTS_XCD_USER_INFO = "/admin/auth/api/v1/accounts/xcd-user-info/"

export const CUSTOMER_ADMIN_WORKSPACE_USERS = "/admin/auth/api/v1/workspaces/users/"

export const CUSTOMER_ADMIN_USERS_RESEND_INVITE = "/admin/auth/api/v1/accounts/resend-invite/"
export const CUSTOMER_ADMIN_USERS_SEND_INVITE = "/admin/auth/api/v1/accounts/send-invite/"

export const CUSTOMER_ADMIN_PUBLIC_SAAS_ADMIN = "/admin/auth/api/v1/public-saas/admin/"

export const CUSTOMER_ADMIN_PUBLIC_SAAS_PROVIDER = `/admin/auth/api/v1/public-saas/service-provider`
export const CUSTOMER_ADMIN_PUBLIC_SAAS_INTEGRATION_DETAILS = (integrationId?: string) =>
  `/admin/auth/api/v1/public-saas/service-details?service_id=${integrationId}`

export const CUSTOMER_ADMIN_PUBLIC_SAAS_DETAILS = "/admin/auth/api/v1/public-saas/idp-urls"
export const CUSTOMER_ADMIN_PUBLIC_SAAS_CERTIFICATE = "/admin/auth/api/v1/public-saas/download-public-key"
export const CUSTOMER_ADMIN_PUBLIC_SAAS_TEMPLATE = (serviceName: string) =>
  `/admin/auth/api/v1/public-saas/template?app=${serviceName}`

export const CUSTOMER_ADMIN_SITES_HOSTING_PROVIDER = "/admin/resource/api/v1/resources/get-hosting-providers"

/*---------------------------------- */
export const AGENTLESS_TUNNEL_CLIENT_URL = "/agentless-tunnel-client/tunnel"

export const AGENTLESS_SERVICE_TEST_CONNECTIVITY =
  "/admin/resource/api/v1/resources/agentless-services/test-connectivity/"
export const RESOURCES_JOBS = "/admin/resource/api/v1/resources/jobs/"

export const GET_USER_ASSOCIATIONS = (userId: number): string =>
  `/admin/auth/api/v1/accounts/users/${userId}/associations/`

export const REMOVE_USER = (userId: number): string => `/admin/auth/api/v1/accounts/users/${userId}/hard-deletion/`

export const APPLICATION_TOP_IDENTITIES_USAGE_ANALYTICS = "/admin/analytics/api/v1/users/usage/"
export const SSIDS = "/services/config/ssid/ssidprofiles"
export const RESOURCES_SSIDS = "/admin/resource/api/v1/resources/ssids/"
export const UPDATE_SSID = "/admin/resource/api/v1/resources/ssids/"

// Application Discovery

export const RECOMMENDED_POLICIES = "/admin/resource/api/v1/discovery/policies/"
export const DISCOVERY_GROUPS = "/admin/resource/api/v1/discovery/groups/"

//LOGOUTS

export const XIQ_LOGOUT = "https://ztna1r1.qa.xcloudiq.com/oauth/logout"
export const XIQ_TME_LOGOUT = "https://tmer1.qa.xcloudiq.com/oauth/logout"
export const XIQ_G2_LOGOUT = "https://g2r1.qa.xcloudiq.com/oauth/logout"

export const XIQ_SS0_LOGOUT = "https://ztna1.qa.xcloudiq.com/sso/logout"
export const XIQ_TME_SSO_LOGOUT = "https://tme.qa.xcloudiq.com/sso/logout"
export const XIQ_G2_SSO_LOGOUT = "https://g2.qa.xcloudiq.com/sso/logout"

// Network Devices - Global Timeout

export const UPDATE_GLOBAL_TIMEOUT = "/cnac/network-policy-engine/api/v1/global-params/auth-session-timeout"
export const GET_GLOBAL_TIMEOUT = "/cnac/network-policy-engine/api/v1/global-params/auth-session-timeout"
export const UPDATE_SESSION_TIMEOUT = "/cnac/network-policy-engine/api/v1/devices/auth-session-timeout"

// Radius Template

export const GET_RADIUS_TEMPLATE = "/cnac/network-policy-engine/external/api/v1/radius-template"
export const GET_RADIUS_TEMPLATE_VARIABLES = "/cnac/network-policy-engine/external/api/v1/radius-template-variables/"
export const CRUD_RADIUS_TEMPLATE = "/cnac/network-policy-engine/external/api/v1/radius-template/"
export const ZTA_DELETE = "/admin/resource/api/v1/resources/devices/end-system/"

// Eula
export const CHECK_EULA_ACCEPTED = "/admin/auth/api/v1/workspaces/eula/"
