type RoleKeyType = keyof typeof Roles
export type RoleValueType = (typeof Roles)[RoleKeyType]

export const ProfileRoles = {
  TENANT_END_USER: "tenant_end_user",
  ZTNA_SUPER_ADMIN: "ztna_super_admin",
  TENANT_SUPER_ADMIN: "tenant_super_admin",
} as const

export const Roles = {
  SUPER_ADMIN: "ztna_super_admin",
  CUSTOMER_SUPER_ADMIN: "tenant_super_admin",
  END_USER: "tenant_end_user",
} as const

export const RolesNameMapping = {
  [Roles.SUPER_ADMIN]: "Ztna Super Admin",
  [Roles.CUSTOMER_SUPER_ADMIN]: "Super Admin",
  [Roles.END_USER]: "End User",
} as const
