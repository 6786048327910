import { createUseStyles } from "react-jss"

const useStatusCellStyles = createUseStyles((theme) => ({
  root: {
    height: "inherit",
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  activeSinceText: {
    fontSize: 13,
    fontWeight: 400,
    fontStyle: "italic",
    color: theme.palette.tertiary[600],
    whiteSpace: "nowrap",
  },
  dot: {
    height: 6,
    width: 6,
    minWidth: 6,
    borderRadius: "50%",
    marginRight: 5,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  danger: {
    backgroundColor: theme.palette.error.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  hidden: {
    display: "none",
  },
  successText: {
    color: theme.palette.success.main,
  },
  dangerText: {
    color: theme.palette.error.main,
  },
  disabled: {
    backgroundColor: theme.palette.grey[800],
  },
  text: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  warnFilledIconContainer: {
    marginLeft: 7.7,
    width: 15.4,
    "& svg": {
      width: 15.4,
      height: 14.2,
      marginTop: 4,
    },
  },
  postIconContainer: {
    marginLeft: 7.7,
    width: 16.4,
    "& svg": {
      width: 16,
      height: 16,
      marginTop: 4,
    },
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 14,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 8,
    paddingBottom: 8,
  },
  arrow: {
    color: theme.palette.primary.main,
  },
  postBadge: {
    borderRadius: 3,
    height: 18,
    "& span": {
      fontSize: 12,
      padding: 5,
    },
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.tertiary[300],
    marginLeft: 5,
  },
  secondaryIconTooltip: {
    marginTop: 6.5,
  },
  secondaryIconContainer: {
    marginTop: 2,
    marginLeft: 5,
  },
}))

export default useStatusCellStyles
