import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { useEffect, useRef } from "react"
import { useDispatch } from "react-redux"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ZtnaIcon from "src/shared/components/Icons/ZtnaIcon"
import ZtnaButton from "src/shared/components/ZtnaButton"
import { openModal } from "src/store/ui"
import { ModalNamesType } from "src/store/ui/types"
import theme from "src/theme"
import { useUpgradeBannerStyles, useUpgradeButtonStyles, useUpgradeMessageStyles } from "./UpgradeBanner.styles"
import { useConnectorsNotifications } from "./useConnectorsNotifications"
import { useRelayNodeNotifications } from "./useRelayNodeNotifications"

const mapStatus = {
  UPGRADE_PENDING: "Upgrade Pending",
  UPGRADE_IN_PROGRESS: "Upgrade In-Progress",
  UPGRADE_FAILED: "Upgrade Failed",
} as const

export type NotificationsParsedType = {
  status: (typeof mapStatus)[keyof typeof mapStatus]
  message: string
  latestVersion?: string
}

interface UpgradeMessageProps {
  showInfoIcon: boolean
  message: string
}

const UpgradeMessage = ({ showInfoIcon, message }: UpgradeMessageProps) => {
  const classes = useUpgradeMessageStyles()

  return (
    <ENTextPassage>
      <div className={classes.root}>
        {showInfoIcon && <ZtnaIcon name="enInfo" size="lg" style={{ color: theme.palette.neutralLight[16] }} />}
        <p className={classes.text} dangerouslySetInnerHTML={{ __html: message }} />
      </div>
    </ENTextPassage>
  )
}

interface UpgradeButtonProps {
  title: string
  modalName: ModalNamesType
  callback?: () => void
}

const UpgradeButton = ({ title, modalName, callback }: UpgradeButtonProps) => {
  const classes = useUpgradeButtonStyles()

  const dispatch = useDispatch()

  return (
    <ZtnaButton
      variant="text"
      title={title}
      buttonType="primary"
      className={classes.button}
      onClick={() => {
        callback?.()
        dispatch(openModal(modalName))
      }}
    />
  )
}

interface UpgradeBannerProps extends NotificationsParsedType {
  type: "connector" | "relayNode"
}

const UpgradeBanner = ({ type, status, message }: UpgradeBannerProps) => {
  const classes = useUpgradeBannerStyles()

  const { getRelayNodeNotifications } = useRelayNodeNotifications({ shouldFetch: type === "relayNode" })
  const { getConnectorsNotifications } = useConnectorsNotifications({ shouldFetch: type === "connector" })

  let buttonTitle = ""
  let callback = () => {}
  let modalName: ModalNamesType | null = null

  if (status === "Upgrade Pending") {
    buttonTitle = "Initiate Upgrade"
    modalName = type === "connector" ? "upgradeAllConnectors" : "upgradeAllRelayNodes"
  } else if (status === "Upgrade Failed") {
    buttonTitle = "Retry Upgrade"
    modalName = type === "connector" ? "retryConnectorUpgrade" : "retryRelayUpgrade"
  } else {
    buttonTitle = "View Progress"
    callback = type === "connector" ? getConnectorsNotifications : getRelayNodeNotifications
    modalName = type === "connector" ? "upgradeConnectorDetails" : "upgradeRelayNodeDetails"
  }

  const toastId = useRef<string | number | null>(null)

  useEffect(() => {
    const isUpgradePending = status === "Upgrade Pending"

    if (toastId.current === null) {
      toastId.current = toast(<UpgradeMessage message={message} showInfoIcon={isUpgradePending} />, {
        closeButton: <UpgradeButton title={buttonTitle} modalName={modalName as ModalNamesType} callback={callback} />,
      })
    } else {
      toast.update(toastId.current, {
        render: <UpgradeMessage message={message} showInfoIcon={isUpgradePending} />,
        closeButton: <UpgradeButton title={buttonTitle} modalName={modalName as ModalNamesType} callback={callback} />,
      })
    }
  }, [message])

  return (
    <ToastContainer
      position="top-center"
      closeOnClick={false}
      draggable={false}
      className={classes.root}
      hideProgressBar
      bodyStyle={{ padding: 0, margin: 0 }}
      autoClose={false}
      limit={1}
    />
  )
}

export default UpgradeBanner
