import { createUseStyles } from "react-jss"

export const useZtnaPaginationStyles = createUseStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 32,
    flexWrap: "wrap",
    gap: 20,
    "& .MuiPagination-ul": {
      flexWrap: "unset",
    },
  },
  divider: {
    height: 22,
  },
  paginationContainer: {
    alignItems: "center",
    display: "flex",
    gap: 10,
    marginLeft: -30,
  },
  actionContainer: {
    alignItems: "center",
    display: "flex",
  },
  icon: {
    padding: 10,
  },
  paginationItem: {
    borderRadius: 2,
    height: 22,
    minWidth: 23,
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.secondary[600],
    borderColor: theme.palette.secondary[600],
    "&:hover": { backgroundColor: theme.palette.neutralLight[16] },
    "&.Mui-selected": {
      color: theme.palette.neutralLight[16],
      backgroundColor: theme.palette.primary[600],
    },
    "&.Mui-selected:hover": {
      backgroundColor: theme.palette.primary[600],
    },
  },
  paginationIcon: {
    marginBottom: 2,
    "& button:has(svg)": {
      color: theme.palette.primary[600],
      "& .MuiSvgIcon-root.MuiPaginationItem-icon": {
        fontSize: 30,
      },
      "&.Mui-disabled": {
        opacity: 1,
        color: theme.palette.secondary[600],
      },
    },
  },
  paginationText: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.secondary[600],
    borderColor: theme.palette.secondary[600],
  },
  input: {
    height: 22,
    marginLeft: 8.5,
    border: `1px solid ${theme.palette.secondary[600]}`,
    borderRadius: 2,
    textAlign: "center",
    fontSize: 16,
    color: theme.palette.grey[300],
    width: 29,
    "&:focus": {
      outline: "none",
      borderColor: theme.palette.text.primary,
    },
    "&:disabled": {
      backgroundColor: "#ebebeb",
      color: theme.palette.grey[800],
    },
  },
  select: {
    minHeight: "22px !important",
    marginLeft: 8.5,
    "& .react-select__value-container": {
      padding: 0,
      paddingLeft: 4,
    },
    "& .react-select__indicator": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}))
