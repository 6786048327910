import { createUseStyles } from "react-jss"

type PropsType = {
  connectorVersion?: string | null
}

export const useRolloutUpgradeStyles = createUseStyles((theme) => ({
  root: {
    minHeight: (props: PropsType) => (props?.connectorVersion ? 218 : 120),
    "&.MuiDialogContent-root:first-child": {
      padding: "22px 24px 25px",
      marginRight: 0,
    },
    "& p": {
      color: theme.palette.grey[300],
    },
  },
  versionsWrapper: {
    margin: "15px auto",
  },
  version: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    paddingLeft: 10,
  },
  bulletDot: {
    width: 4,
    height: 4,
    backgroundColor: theme.palette.grey[300],
    borderRadius: "50%",
  },
  errorContainer: {
    marginTop: 15,
  },
}))
