import { createUseStyles } from "react-jss"

export const useConnectorExpandedInstancesViewStyles = createUseStyles((theme) => ({
  table: {
    "& tr:nth-child(odd)": {
      height: 47,
    },
    "& h6, td": {
      fontSize: 14,
    },
  },
  root: {
    margin: -15,
    marginLeft: -15,
    width: "100%",
  },
  loadingBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 100,
  },
  loader: {
    width: 100,
    height: 100,
  },
  chipInfoContainer: {
    display: "flex",
    justifyContent: "start",
    gap: 8,
    flexWrap: "wrap",
    fontWeight: 500,
  },
  connectorAttribute: {
    lineHeight: 2,
    color: theme.palette.grey[300],
    fontSize: 15,
    paddingLeft: 10,
  },
  connectorProperty: {
    color: theme.palette.grey[300],
    backgroundColor: `${theme.palette.grey[300]}1a`,
    fontSize: 14,
    margin: "0px 3px",
  },
  infoContainer: {
    gap: 8,
    display: "flex",
    flexWrap: "wrap",
    marginTop: 10,
  },
  columnClass: {
    backgroundColor: `${theme.palette.background.paper} !important`,
  },
  divider: {
    margin: "14px 0",
    width: "100%",
  },
  blankSlate: {
    display: "flex",
    gap: 8,
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    width: "100%",
    color: theme.palette.grey.A200,
    "& span": {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 1,
    },
  },
  cloudIdentifierCell: {
    marginTop: 5,
    display: "flex",
    justifyContent: "start",
    gap: 10,
  },
  instanceId: {
    paddingTop: 5,
  },
  success: {
    color: theme.palette.success[500],
    backgroundColor: theme.palette.success[700],
    fontWeight: 600,
  },
  danger: {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error[700],
    fontWeight: 600,
  },
  fitContent: { width: "fit-content" },
}))
