import { createUseStyles } from "react-jss"

export const useDataGridStyles = createUseStyles((theme) => ({
  tableTaskBar: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 20px",
    borderRadius: "8px 8px 0 0",
    border: `1px solid ${theme.palette.background.surfaceElevation3}`,
    alignItems: "center",
    minHeight: 64,
    gap: 40,
  },
  searchBar: {
    width: 400,
  },
  rightAligner: {
    marginLeft: "auto",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  actionBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 20px",
    minHeight: 35,
    whiteSpace: "nowrap",
  },
  "@keyframes spin": {
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(360deg)" },
  },
  animation: {
    display: "inline-flex",
    animationName: "spin",
    animationDuration: "1000ms",
    animationIterationCount: "infinite",
    animationTimingFunction: "cubic-bezier(0.42, 0, 0.44, 0.9)",
  },
  disabledRefresh: {
    pointerEvents: "none",
    display: "inline-flex",
    animationName: "spin",
    animationDuration: "1000ms",
    animationIterationCount: "infinite",
    animationTimingFunction: "cubic-bezier(0.42, 0, 0.44, 0.9)",
  },
  marginRight: {
    marginRight: 10,
  },
}))
