import clsx from "clsx"
import { useFeatures } from "flagged"
import { Suspense, useEffect, useMemo, useRef, useState } from "react"
import { isMobile } from "react-device-detect"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, useLocation as useRouterLocation } from "react-router-dom"
import AppContainerErrorBoundaries from "src/components/ErrorBoundaries/AppContainerErrorBoundary"
import SideNav from "src/components/SideNav"
import TopNav from "src/components/TopNav"
import { TABS_BANNER_HEIGHT, UPGRADE_BANNER_HEIGHT } from "src/constants/layout"
import EncryptionProtocols from "src/pages/EncryptionProtocols"
import IpsecConfigurations from "src/pages/EncryptionProtocols/IpsecConfigurations"
import Eula from "src/pages/Eula"
import RetryConnectorInstanceUpgrade from "src/pages/ServiceConnectors/RetryConnectorInstanceUpgrade"
import { END_USER_BASE_ROUTE_PREFIX, Roles } from "src/routes/config"
import { getAllowedRoutes, getSelectedRoute } from "src/routes/utils"
import { sendLocationData } from "src/services/api/Location.service"
import useLocation from "src/services/api/swrHooks/useLocation"
import CircularLoader from "src/shared/components/CicularLoader/CircularLoader"
import { RootState } from "src/store"
import {
  closeModal,
  resetBreadCrumbs,
  setAllowedRoutes,
  setConnectorsNotificationsData,
  setCurrentRoute,
  setRelayNodesNotificationsData,
  setShowLicenseValidation,
  setSideNavCurrentRoute,
  setToasterState,
  setUpgradeRelayState,
  toggleSideNav,
  updateSignals,
} from "src/store/ui/uiSlice"
import theme from "src/theme"
import { APP_LAYOUT_GRID_ROW_GAP, APP_MARGIN } from "src/utils/constants"
import Breadcrumbs from "../Breadcrumbs"
import LicenseValidationBanner from "../LicenseValidationBanner"
import TabsView from "../TabsView"
import { useAppContainerStyles } from "./AppContainer.styles"
import UpgradeBanner, { useConnectorsNotifications, useRelayNodeNotifications } from "./Banners/UpgradeBanner"
import ConnectorsUpgradeDetailsModal from "./Banners/UpgradeBanner/ConnectorsUpgradeDetailsModal"
import InitiateUpgradeModal from "./Banners/UpgradeBanner/InitiateUpgradeModal"
import RelayNodesUpgradeDetailsModal from "./Banners/UpgradeBanner/RelayNodesUpgradeDetailsModal"
import useRefreshBrowserCookie from "./useRefreshBrowserCookie"

const AppContainer: React.FC<React.PropsWithChildren<any>> = () => {
  const {
    appContainer: {
      showTopNav,
      showDrawer,
      showBreadcrumbs,
      showPageHeader,
      defaultSpacing,
      background,
      shouldRenderAppContainer,
    },
    breadCrumbs,
    openedModals,
    routeInfo: { currentRoute },
    upgradeNotificationsData,
    upgradeRelayState,
    sideNav: { isOpened },
  } = useSelector((state: RootState) => state.ui)

  const [isScrolled, setIsScrolled] = useState(false)
  const appHeaderRef = useRef<HTMLDivElement | null>(
    document.getElementById("app-container-page-header-container") as HTMLDivElement,
  )
  const flags = useFeatures()
  const dispatch = useDispatch()
  const role = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData") || "{}")?.role : ""
  const isEulaAccepted = localStorage.getItem("userData")
    ? !!JSON.parse(localStorage.getItem("userData") || "{}")?.workspaceData?.isEulaAccepted
    : false
  const isSideBarOpened = useSelector((state: RootState) => state.ui.sideNav.isOpened)
  const shouldFetch = role === Roles.CUSTOMER_SUPER_ADMIN
  const { data: locationData } = useLocation({ shouldFetch }, role)
  const { pathname, search } = useRouterLocation()

  const queries = new URLSearchParams(search)
  const isRedirectedFromEndUser = queries.get("redirect_url") || ""
  const showTabsView = currentRoute?.tabs?.length
  const isDataGrid = currentRoute?.isDataGrid
  const showUpgradeConnectorBanner = !!upgradeNotificationsData?.connectorUpgradeData?.status
  const showUpgradeRelayBanner =
    upgradeNotificationsData?.relayNodeUpgradeData?.status &&
    upgradeNotificationsData?.relayNodeUpgradeData?.status !== "Upgrade In-Progress"
  const showBanners =
    !window.location.pathname.includes(END_USER_BASE_ROUTE_PREFIX) &&
    !isRedirectedFromEndUser &&
    (showUpgradeConnectorBanner || showUpgradeRelayBanner)

  const classes = useAppContainerStyles({
    isSideBarOpened: isSideBarOpened,
    tabsBannerHeight: showTabsView ? TABS_BANNER_HEIGHT : 0,
    upgradeBannerHeight:
      (showUpgradeConnectorBanner ? UPGRADE_BANNER_HEIGHT : 0) + (showUpgradeRelayBanner ? UPGRADE_BANNER_HEIGHT : 0),
    appHeaderHeight: appHeaderRef.current?.clientHeight || 0,
  })
  const isEndUserPortal = pathname.includes(END_USER_BASE_ROUTE_PREFIX) && role !== Roles.SUPER_ADMIN
  const [routes] = role ? getAllowedRoutes([isEndUserPortal ? Roles.END_USER : role], flags).reverse() : []
  const { childs: allowedRoutes = [] } = routes

  useRefreshBrowserCookie({ shouldFetch: role === Roles.CUSTOMER_SUPER_ADMIN })

  useEffect(() => {
    document.addEventListener("scroll", () => {
      if (
        appHeaderRef.current &&
        document.documentElement.scrollTop >=
          appHeaderRef.current?.getBoundingClientRect().top - APP_MARGIN - APP_LAYOUT_GRID_ROW_GAP
      ) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    })
  }, [])

  useEffect(() => {
    if (currentRoute?.tabs) dispatch(resetBreadCrumbs())
  }, [JSON.stringify(currentRoute?.tabs)])

  useEffect(() => {
    dispatch(setAllowedRoutes(allowedRoutes))
  }, [])

  useEffect(() => {
    const selectedRoute = getSelectedRoute(pathname, allowedRoutes)
    dispatch(
      setSideNavCurrentRoute(
        getSelectedRoute(selectedRoute?.tabs ? (selectedRoute.tabs[0]?.route as string) : pathname, allowedRoutes)?.id,
      ),
    )
    if (isMobile) {
      dispatch(toggleSideNav())
    }
    dispatch(setCurrentRoute(selectedRoute))
    dispatch(setShowLicenseValidation(true))
  }, [pathname])

  useEffect(() => {
    if (locationData) {
      sendLocationData(locationData, role).catch((err) => {
        console.error(err)
      })
    }
    if (!locationData && role === Roles.END_USER) {
      sendLocationData({}, Roles.END_USER)
    }
  }, [JSON.stringify(locationData)])

  // useEffect(() => {
  //   if (user.name && user.name !== "" && data?.secretKey) {
  //     ;(window as any).Intercom("boot", {
  //       app_id: INTERCOM_APP_ID,
  //       email: user.email,
  //       name: user.name,
  //       user_id: user.email,
  //       user_hash: data?.secretKey,
  //       created_at: new Date().toUTCString(),
  //     })
  //   }
  //   return () => (window as any).Intercom("shutdown")
  // }, [JSON.stringify(user), data?.secretKey])

  const { connectorsNotificationsData, getConnectorsNotifications } = useConnectorsNotifications({
    shouldFetch: role === Roles.CUSTOMER_SUPER_ADMIN,
  })

  const { relayNodeNotificationsData } = useRelayNodeNotifications({
    shouldFetch: role === Roles.SUPER_ADMIN,
  })

  useEffect(() => {
    connectorsNotificationsData && dispatch(setConnectorsNotificationsData(connectorsNotificationsData))
  }, [JSON.stringify(connectorsNotificationsData)])

  useEffect(() => {
    if (relayNodeNotificationsData) dispatch(setRelayNodesNotificationsData(relayNodeNotificationsData))

    if (
      relayNodeNotificationsData?.status &&
      relayNodeNotificationsData?.status === "Upgrade In-Progress" &&
      upgradeRelayState !== "in-progress"
    )
      dispatch(setUpgradeRelayState("in-progress"))

    if (!relayNodeNotificationsData?.status && upgradeRelayState === "in-progress")
      dispatch(setUpgradeRelayState("successful"))

    if (relayNodeNotificationsData?.status && relayNodeNotificationsData?.status === "Upgrade Failed")
      dispatch(setUpgradeRelayState("failed"))

    dispatch(updateSignals(["getRelayNodes"]))
  }, [JSON.stringify(relayNodeNotificationsData)])

  useEffect(() => {
    upgradeRelayState === "in-progress" &&
      dispatch(
        setToasterState({
          message: "Keep an eye on the UZTNA Gateway upgrade progress using the status in the top navigation bar.",

          autoHide: false,
          anchorOrigin: { vertical: "top", horizontal: "center" },
          isCloseable: true,
        }),
      )

    upgradeRelayState === "successful" &&
      dispatch(
        setToasterState({
          message: `All relays have been successfully upgraded to ${relayNodeNotificationsData?.latestVersion}.`,
          type: "success",
          autoHide: false,
          anchorOrigin: { vertical: "top", horizontal: "center" },
          isCloseable: true,
          handleOk: () => {
            dispatch(setUpgradeRelayState("pending"))
          },
          handleClose: () => {
            dispatch(setUpgradeRelayState("pending"))
          },
        }),
      )
  }, [upgradeRelayState])

  const UpgradeBanners = useMemo(
    () => (
      <>
        {showUpgradeConnectorBanner && (
          <UpgradeBanner
            type="connector"
            status={upgradeNotificationsData?.connectorUpgradeData?.status!}
            message={upgradeNotificationsData?.connectorUpgradeData?.message!}
          />
        )}
        {showUpgradeRelayBanner && (
          <UpgradeBanner
            type="relayNode"
            status={upgradeNotificationsData?.relayNodeUpgradeData?.status!}
            message={upgradeNotificationsData?.relayNodeUpgradeData?.message!}
          />
        )}
      </>
    ),
    [upgradeNotificationsData],
  )

  const LicenseBanner = role !== Roles.SUPER_ADMIN && <LicenseValidationBanner />

  return (
    <>
      {shouldRenderAppContainer ? (
        <div className={classes.root}>
          <div className={classes.upgradeBanner}>{UpgradeBanners}</div>
          {showTopNav && <TopNav />}
          {showDrawer && <SideNav />}
          <div className={clsx(classes.contentContainer, { [classes.fixedHeightContent]: isDataGrid })}>
            <div ref={appHeaderRef} className={clsx(classes.appHeader, { [classes.appHeaderIsSticky]: isScrolled })}>
              <div id="app-container-page-header-container"></div>
              {(showTabsView || showBanners) && (
                <div
                  className={clsx(
                    classes.banner,
                    { [classes.navMargin]: !showPageHeader && !showBreadcrumbs },
                    { [classes.navMarginSidebarOpen]: (!showPageHeader || !showBreadcrumbs) && isOpened },
                  )}
                >
                  {currentRoute?.tabs ? (
                    <TabsView
                      tabsArray={currentRoute.tabs}
                      tabContainerClass={classes.tabsContainer}
                      indicatorColor={theme.palette.neutralLight[16]}
                      tabPanelClass={classes.tabPanel}
                      activeTabPosition={currentRoute.tabs.findIndex((item) => pathname === item.route)}
                    >
                      {LicenseBanner}
                    </TabsView>
                  ) : (
                    LicenseBanner
                  )}
                </div>
              )}
            </div>
            <main
              className={clsx(classes.content, {
                [classes.bgGrey]: background === "grey",
                [classes.fixedHeightContent]: currentRoute?.isDataGrid,
              })}
            >
              {showBreadcrumbs && (
                <div
                  className={clsx(classes.breadcrumbContainer, classes.navMargin, {
                    [classes.navMarginSidebarOpen]: isOpened,
                  })}
                >
                  <Breadcrumbs {...breadCrumbs} />
                </div>
              )}

              <AppContainerErrorBoundaries currentRoute={currentRoute}>
                <div
                  className={clsx(classes.container, {
                    [classes.containerSpacing]: defaultSpacing,
                    [classes.endUserContainerSpacing]: role === Roles.END_USER,
                  })}
                >
                  <Suspense
                    fallback={
                      <div className={classes.loader}>
                        <CircularLoader size="xl" />
                      </div>
                    }
                  >
                    <Outlet />
                  </Suspense>
                </div>
              </AppContainerErrorBoundaries>
            </main>
          </div>
        </div>
      ) : (
        <AppContainerErrorBoundaries currentRoute={currentRoute}>
          <Suspense
            fallback={
              <div className={classes.loader}>
                <CircularLoader size="xl" />
              </div>
            }
          >
            <Outlet />
          </Suspense>
        </AppContainerErrorBoundaries>
      )}

      {openedModals["upgradeAllConnectors"] && (
        <InitiateUpgradeModal
          type="connector"
          onModalClose={() => {
            dispatch(closeModal("upgradeAllConnectors"))
            getConnectorsNotifications()
          }}
        />
      )}

      {openedModals["retryConnectorUpgrade"] && connectorsNotificationsData && (
        <RetryConnectorInstanceUpgrade
          onModalClose={() => {
            dispatch(closeModal("retryConnectorUpgrade"))
            getConnectorsNotifications()
            dispatch(updateSignals(["getServiceConnectors", "getConnectorsExpandedViewData"]))
          }}
          instanceData={{
            name: connectorsNotificationsData.instanceName,
            upgradeFailedMessage: connectorsNotificationsData.instanceUpgradeFailedMessage,
          }}
          connectorName={connectorsNotificationsData.hostName}
        />
      )}

      {openedModals["upgradeAllRelayNodes"] && (
        <InitiateUpgradeModal type="relayNode" onModalClose={() => dispatch(closeModal("upgradeAllRelayNodes"))} />
      )}

      {openedModals["upgradeRelayNodeDetails"] && <RelayNodesUpgradeDetailsModal />}
      {openedModals["upgradeConnectorDetails"] && <ConnectorsUpgradeDetailsModal />}
      {openedModals["encryptionProtocols"] && <EncryptionProtocols />}
      {openedModals["ipsecConfigurations"] && <IpsecConfigurations />}
      {role === Roles.CUSTOMER_SUPER_ADMIN && !isEulaAccepted && <Eula />}
    </>
  )
}

export default AppContainer
