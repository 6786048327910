import { ENTooltip } from "en-react/dist/src/components/Tooltip"
import React from "react"

interface ZtnaTooltipProps {
  children: React.ReactNode
  title: React.ReactNode
  classes?: any
  prefix?: string
  placement?: "top" | "bottom" | "left" | "right"
  isInteractive?: boolean
  arrow?: boolean
  open?: boolean
  shouldLineBreak?: boolean
  enableTextWrap?: boolean
  customStyle?: Object
  cssPosition?: "absolute" | "fixed"
}

const ZtnaTooltip: React.FC<React.PropsWithChildren<ZtnaTooltipProps>> = ({
  children,
  title,
  prefix,
  placement = "top",
  isInteractive = false,
  arrow = true,
  open = false,
  shouldLineBreak = true,
  enableTextWrap = true,
  customStyle,
  cssPosition = "fixed",
}) => {
  return (
    <ENTooltip
      position={placement}
      isInteractive={isInteractive}
      hasArrow={arrow}
      isActive={open}
      style={{ display: "flex", ...customStyle }}
      styleModifier="en-u-width-200"
      enableTextWrap={enableTextWrap}
      cssPosition={cssPosition}
    >
      <>
        <div slot="trigger">{children}</div>
        {prefix && <span slot="prefix">{prefix}</span>}
        <div style={shouldLineBreak ? { lineBreak: "normal" } : {}}>{title}</div>
      </>
    </ENTooltip>
  )
}

export default ZtnaTooltip
