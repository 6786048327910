import theme from "src/theme"

export const BackArrowIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="6.587" height="10.854" viewBox="0 0 6.587 10.854">
    <path
      id="Icon_awesome-chevron-left"
      data-name="Icon awesome-chevron-left"
      d="M2.108,7.653l4.83-4.83a.6.6,0,0,1,.844,0l.563.563a.6.6,0,0,1,0,.843L4.518,8.075l3.828,3.846a.6.6,0,0,1,0,.843l-.563.563a.6.6,0,0,1-.844,0L2.108,8.5A.6.6,0,0,1,2.108,7.653Z"
      transform="translate(-1.933 -2.648)"
      fill="currentColor"
    />
  </svg>
)

export const BarChartIcon = ({ color = theme.palette.grey.A200 }): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14.863" height="14.863" viewBox="0 0 14.863 14.863">
    <path
      data-name="XCI Insights"
      d="M5.652 8.626h.212a1.491 1.491 0 0 1 1.486 1.486v7.431a1.491 1.491 0 0 1-1.485 1.487h-.213a1.491 1.491 0 0 1-1.486-1.486v-7.432a1.491 1.491 0 0 1 1.486-1.486zM11.6 4.167a1.491 1.491 0 0 1 1.486 1.486v11.89a1.486 1.486 0 1 1-2.973 0V5.653A1.491 1.491 0 0 1 11.6 4.167zm5.945 8.493a1.491 1.491 0 0 1 1.486 1.486v3.4a1.486 1.486 0 1 1-2.973 0v-3.4a1.491 1.491 0 0 1 1.486-1.486z"
      transform="translate(-4.166 -4.167)"
      fill={color}
    />
  </svg>
)

export const BasicLicenseIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14.083" viewBox="0 0 13 14.083">
    <g data-name="Group 4341">
      <path
        data-name="Subtraction 7"
        d="M13 6.5H0a6.5 6.5 0 0 1 11.1-4.6A6.458 6.458 0 0 1 13 6.5z"
        style={{ fill: "#409" }}
      />
      <path
        data-name="Subtraction 8"
        d="M13 0H0a6.458 6.458 0 0 0 1.9 4.6 6.5 6.5 0 0 0 9.193 0A6.458 6.458 0 0 0 13 0z"
        transform="translate(0 7.583)"
        style={{ fill: "#409" }}
      />
    </g>
  </svg>
)

export const BillingIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
    <g data-name="Group 4896">
      <g data-name="Ellipse 421" stroke={theme.palette.grey.A200} strokeWidth="1.5px" fill="none">
        <circle cx="8.5" cy="8.5" r="8.5" stroke="none" />
        <circle cx="8.5" cy="8.5" r="7.75" fill="none" />
      </g>
      <text
        data-name="?"
        transform="translate(9.001 13)"
        fill={theme.palette.grey.A200}
        fontSize="12px"
        fontFamily="NotoSans-SemiBold,Noto Sans"
        fontWeight="600"
      >
        <tspan x="-2.766" y="0">
          ?
        </tspan>
      </text>
    </g>
  </svg>
)

export const BlankSlateIcon: React.FC<{ height?: string; width?: string; color?: string }> = ({
  height = "88.5px",
  width = "88.5px",
  color = "currentColor",
}): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={height} height={width} viewBox="0 0 88.5 88.5">
    <g id="Icon_ionic-ios-information-circle-outline" transform="translate(-3.375 -3.375)">
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        id="Path_149"
        d="M16.552 14.942a4.259 4.259 0 0 1 4.361-4.234 4.247 4.247 0 1 1 0 8.488 4.277 4.277 0 0 1-4.361-4.254zm.3 7.595h8.127v30.656h-8.13z"
        transform="translate(26.691 14.855)"
      />
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        id="Path_150"
        d="M47.625 9.332a38.3 38.3 0 0 1 27.082 65.375 38.3 38.3 0 0 1-54.164-54.164A38.04 38.04 0 0 1 47.625 9.332m0-5.957a44.25 44.25 0 1 0 44.25 44.25 44.243 44.243 0 0 0-44.25-44.25z"
      />
    </g>
  </svg>
)

export const BoxedCross = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22">
    <g data-name="Group 10979" transform="translate(-3060.5 -10338.5)">
      <rect
        width="20"
        height="21"
        rx="2"
        transform="translate(3061 10339)"
        fill="#f9fafb"
        stroke={theme.palette.secondary[600]}
        strokeMiterlimit={10}
      />
      <path
        data-name="Path 3605"
        d="M-225.085-36.061c.648-.641 1.249-1.239 1.853-1.833a1.234 1.234 0 0 1 1.831-.17 1.273 1.273 0 0 1-.164 1.84c-.3.306-.593.608-.9.9s-.627.573-1 .914l1.829 1.861c.649.661.739 1.366.24 1.872-.543.551-1.2.469-1.914-.246-.566-.569-1.126-1.145-1.757-1.785-.574.586-1.113 1.141-1.657 1.69a4.1 4.1 0 0 1-.541.5 1.13 1.13 0 0 1-1.514-.191 1.159 1.159 0 0 1 .041-1.582c.66-.665 1.368-1.283 2.218-2.071-.759-.691-1.41-1.257-2.03-1.855a1.254 1.254 0 0 1-.2-1.832 1.229 1.229 0 0 1 1.83.148c.605.594 1.198 1.201 1.835 1.84z"
        transform="translate(3296.075 10383.854)"
        fill={theme.palette.secondary[600]}
      />
    </g>
  </svg>
)

export const BrightCircularTickIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="132.094" height="132.094" viewBox="0 0 132.094 132.094">
    <g data-name="Group 5166">
      <path
        data-name="Subtraction 12"
        d="M6471.5 9036.008a38.5 38.5 0 1 1 27.225-11.276 38.264 38.264 0 0 1-27.225 11.276zm-13.789-41.121a3.105 3.105 0 0 0-2.193 5.3l9.533 9.539a3.11 3.11 0 0 0 2.2.907.479.479 0 0 0 .083 0h.091a3.136 3.136 0 0 0 2.254-1.162l17.6-22.007a3.107 3.107 0 0 0-4.854-3.88l-15.433 19.3-7.085-7.083a3.085 3.085 0 0 0-2.194-.914z"
        transform="translate(-6405.457 -8931.458)"
        style={{ fill: theme.palette.success[500] }}
      />
      <g data-name="Group 5165" style={{ opacity: ".42" }}>
        <path
          data-name="Path 3619"
          d="M456.531 204.728q1.52-1.675 3.2-3.2c-7.48-7.556-13.913-13.567-15.339-12.142s4.583 7.862 12.139 15.342z"
          transform="translate(-425.044 -170.044)"
          style={{ fill: theme.palette.success[500] }}
        />
        <path
          data-name="Path 3620"
          d="M712.377 204.728c7.556-7.48 13.567-13.913 12.142-15.339s-7.859 4.585-15.339 12.142q1.675 1.52 3.197 3.197z"
          transform="translate(-611.769 -170.044)"
          style={{ fill: theme.palette.success[500] }}
        />
        <path
          data-name="Path 3621"
          d="M398.777 342.289q0-1.2.06-2.39c-10.634-.047-19.437.292-19.437 2.39s8.8 2.437 19.437 2.39q-.06-1.188-.06-2.39z"
          transform="translate(-379.4 -276.242)"
          style={{ fill: theme.palette.success[500] }}
        />
        <path
          data-name="Path 3622"
          d="M597.289 143.777q1.2 0 2.39.06c.047-10.634-.292-19.437-2.39-19.437s-2.437 8.8-2.39 19.437q1.187-.06 2.39-.06z"
          transform="translate(-531.242 -124.4)"
          style={{ fill: theme.palette.success[500] }}
        />
        <path
          data-name="Path 3623"
          d="M712.377 454.18q-1.52 1.675-3.2 3.2c7.48 7.556 13.913 13.567 15.339 12.142s-4.583-7.862-12.139-15.342z"
          transform="translate(-611.769 -356.769)"
          style={{ fill: theme.palette.success[500] }}
        />
        <path
          data-name="Path 3624"
          d="M760.8 339.9q.06 1.187.06 2.39t-.06 2.39c10.634.047 19.437-.292 19.437-2.39s-8.807-2.438-19.437-2.39z"
          transform="translate(-648.139 -276.242)"
          style={{ fill: theme.palette.success[500] }}
        />
        <path
          data-name="Path 3625"
          d="M456.531 454.18c-7.556 7.48-13.567 13.913-12.142 15.339s7.859-4.586 15.339-12.142q-1.675-1.52-3.197-3.197z"
          transform="translate(-425.044 -356.769)"
          style={{ fill: theme.palette.success[500] }}
        />
        <path
          data-name="Path 3626"
          d="M597.289 505.856q-1.2 0-2.39-.06c-.047 10.634.292 19.437 2.39 19.437s2.437-8.8 2.39-19.437q-1.187.06-2.39.06z"
          transform="translate(-531.242 -393.139)"
          style={{ fill: theme.palette.success[500] }}
        />
      </g>
    </g>
  </svg>
)

export const BuildingIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12.02" height="13.737" viewBox="0 0 12.02 13.737">
    <path
      data-name="Icon awesome-building"
      d="M11.7 12.878h-.537V.644A.644.644 0 0 0 10.517 0H1.5a.644.644 0 0 0-.641.644v12.234H.322A.322.322 0 0 0 0 13.2v.537h12.02V13.2a.322.322 0 0 0-.32-.322zM3.434 2.039a.322.322 0 0 1 .322-.322h1.073a.322.322 0 0 1 .322.322v1.073a.322.322 0 0 1-.322.322H3.756a.322.322 0 0 1-.322-.322zm0 2.576a.322.322 0 0 1 .322-.322h1.073a.322.322 0 0 1 .322.322v1.073a.322.322 0 0 1-.322.322H3.756a.322.322 0 0 1-.322-.322zm1.4 3.971H3.756a.322.322 0 0 1-.322-.322V7.19a.322.322 0 0 1 .322-.322h1.073a.322.322 0 0 1 .322.322v1.073a.322.322 0 0 1-.322.322zm2.039 4.293H5.151v-2.255a.322.322 0 0 1 .322-.322h1.073a.322.322 0 0 1 .322.322zm1.712-4.616a.322.322 0 0 1-.322.322H7.19a.322.322 0 0 1-.322-.322V7.19a.322.322 0 0 1 .322-.322h1.073a.322.322 0 0 1 .322.322zm0-2.576a.322.322 0 0 1-.322.322H7.19a.322.322 0 0 1-.322-.322V4.615a.322.322 0 0 1 .322-.322h1.073a.322.322 0 0 1 .322.322zm0-2.576a.322.322 0 0 1-.322.322H7.19a.322.322 0 0 1-.322-.322V2.039a.322.322 0 0 1 .322-.322h1.073a.322.322 0 0 1 .322.322z"
      style={{ fill: theme.palette.warning[500] }}
    />
  </svg>
)

export const BuildingOnPremIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16.632" height="19.008" viewBox="0 0 16.632 19.008">
    <path
      data-name="Icon awesome-building"
      d="M16.186 17.82h-.742V.891A.891.891 0 0 0 14.553 0H2.079a.891.891 0 0 0-.891.891V17.82H.445a.446.446 0 0 0-.445.445v.742h16.632v-.742a.446.446 0 0 0-.446-.445zm-11.434-15a.446.446 0 0 1 .448-.444h1.482a.446.446 0 0 1 .445.445v1.485a.446.446 0 0 1-.445.445H5.2a.446.446 0 0 1-.445-.445zm0 3.564A.446.446 0 0 1 5.2 5.94h1.482a.446.446 0 0 1 .445.445V7.87a.446.446 0 0 1-.445.445H5.2a.446.446 0 0 1-.445-.445zm1.93 5.494H5.2a.446.446 0 0 1-.445-.445V9.949A.446.446 0 0 1 5.2 9.5h1.482a.446.446 0 0 1 .445.445v1.485a.446.446 0 0 1-.445.45zM9.5 17.82H7.128V14.7a.446.446 0 0 1 .445-.445h1.485a.446.446 0 0 1 .442.445zm2.376-6.385a.446.446 0 0 1-.445.445H9.949a.446.446 0 0 1-.445-.445V9.949a.446.446 0 0 1 .445-.449h1.485a.446.446 0 0 1 .445.445zm0-3.564a.446.446 0 0 1-.445.445H9.949A.446.446 0 0 1 9.5 7.87V6.385a.446.446 0 0 1 .445-.445h1.485a.446.446 0 0 1 .445.445zm0-3.564a.446.446 0 0 1-.445.445H9.949a.446.446 0 0 1-.449-.446V2.821a.446.446 0 0 1 .445-.445h1.485a.446.446 0 0 1 .445.445z"
      style={{ fill: theme.palette.tertiary[500] }}
    />
  </svg>
)

export const BuildingPrivateIcon: React.FC<{ height?: string; width?: string; color?: string }> = ({
  height = "22",
  width = "22",
  color = theme.palette.grey.A200,
}): JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5834 10.0833V2.75H6.41666V6.41666H2.75V19.25H10.0833V15.5834H11.9166V19.25H19.25V10.0833H15.5834ZM6.41666 17.4166H4.58334V15.5834H6.41666V17.4166ZM6.41666 13.75H4.58334V11.9166H6.41666V13.75ZM6.41666 10.0833H4.58334V8.25H6.41666V10.0833ZM10.0833 13.75H8.25V11.9166H10.0833V13.75ZM10.0833 10.0833H8.25V8.25H10.0833V10.0833ZM10.0833 6.41666H8.25V4.58334H10.0833V6.41666ZM13.75 13.75H11.9166V11.9166H13.75V13.75ZM13.75 10.0833H11.9166V8.25H13.75V10.0833ZM13.75 6.41666H11.9166V4.58334H13.75V6.41666ZM17.4166 17.4166H15.5834V15.5834H17.4166V17.4166ZM17.4166 13.75H15.5834V11.9166H17.4166V13.75Z"
      fill={color}
    />
  </svg>
)
