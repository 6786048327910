import clsx from "clsx"
import { useEffect, useState } from "react"
import Select, { components } from "react-select"
import ZtnaIcon from "src/shared/components/Icons"
import theme from "src/theme"
import ZtnaTooltip from "../../ZtnaTooltip"
import { useFormComponentsStyles } from "../FormComponents.styles"
import { ZtnaSelectType } from "../FormComponents.types"

const SelectBorder = `1px solid ${theme.palette.secondary[600]}`
const SelectBorderFocused = `2px solid ${theme.palette.primary[600]}`
const SelectBorderError = `1px solid ${theme.palette.error.dark}`

const ZtnaSelect: React.FC<ZtnaSelectType> = ({
  id,
  isSearchable = true,
  value = "",
  options = [],
  error,
  isError,
  onChange,
  placeholder = "Select",
  disabled = false,
  optionValue = "value",
  optionLabel = "label",
  isLoading = false,
  isClearable = false,
  customOption = null,
  fullObjectSelection = false,
  menuPosition = "fixed",
  styles,
  className,
  menuRenderer,
  menuIsOpen,
  setMenuIsOpen,
  minSelectHeight,
  objectName = "",
  objectCreationFunction = () => {},
  ...extraProps
}): JSX.Element => {
  const classes = useFormComponentsStyles()
  const onOptionChange = (selected: any) => {
    if ((selected || selected === null) && selected?.id !== `create-new-${objectName}-btn`) {
      if (fullObjectSelection) onChange(selected)
      else {
        const selectedValue = selected ? selected[optionValue] : null
        onChange(selectedValue)
      }
    }
  }

  const selectedValue = fullObjectSelection ? value : options.find((option: any) => value === option[optionValue])
  const [menuOpened, setMenuOpened] = useState(menuIsOpen)

  useEffect(() => {
    setMenuOpened(menuIsOpen)
  }, [menuIsOpen])
  const DropdownIndicator = (dropDownProps: any) => (
    <ZtnaTooltip
      arrow
      placement="top"
      classes={{ tooltip: error ? classes.errorTooltip : classes.errorTooltipHidden, arrow: classes.errorArrow }}
      title={
        <div className={classes.errorText}>
          <span className={classes.dangerIcon}>
            {error && <ZtnaIcon color={theme.palette.error.main} height={16} name="error" />}
          </span>
          <span>{error?.message}</span>
        </div>
      }
    >
      <div>
        <components.DropdownIndicator
          className={clsx({
            [classes.popOverOpen]: menuOpened && !error,
          })}
          {...dropDownProps}
        >
          {!error ? (
            <p role="presentation" className={classes.caretIconClose} onClick={() => setMenuIsOpen?.(!menuIsOpen)}>
              <ZtnaIcon name="caret" color={disabled ? theme.palette.secondary[600] : "#61728f"} />
            </p>
          ) : (
            <div data-testid="select-error">
              <ZtnaIcon name="error" color={theme.palette.neutralLight[16]} />
            </div>
          )}
        </components.DropdownIndicator>
      </div>
    </ZtnaTooltip>
  )

  const formattedOptions = objectName
    ? [
        {
          name: (
            <button onClick={objectCreationFunction} className={classes.creationButton}>
              {`Create New ${objectName}`}
            </button>
          ),
          id: `create-new-${objectName}-btn`,
        },
        ...options,
      ]
    : options
  return (
    <>
      <Select
        menuIsOpen={menuOpened}
        onMenuOpen={() => {
          setMenuOpened(true)
          setMenuIsOpen?.(true)
        }}
        onMenuClose={() => setMenuOpened(menuIsOpen)}
        id={id}
        menuPosition={menuPosition}
        isDisabled={disabled}
        isLoading={isLoading}
        isClearable={isClearable}
        className={clsx(classes.select, className, { [classes.errorSelect]: error })}
        classNamePrefix="react-select"
        isSearchable={isSearchable}
        value={selectedValue || null}
        options={formattedOptions}
        getOptionLabel={(option) => option[optionLabel]}
        getOptionValue={(option) => option[optionValue]}
        isOptionDisabled={(option) => option.disabled}
        placeholder={placeholder}
        onChange={onOptionChange}
        components={menuRenderer ? { DropdownIndicator, Menu: menuRenderer } : { DropdownIndicator }}
        {...extraProps}
        styles={
          styles || {
            control: (provided, state) => ({
              ...provided,
              borderRadius: 2,
              boxShadow: "none",
              border: isError ? SelectBorderError : state.isFocused ? SelectBorderFocused : SelectBorder,
              "&:hover": {
                borderColor: isError ? SelectBorderError : state.isFocused ? SelectBorderFocused : SelectBorder,
              },
              backgroundColor: state.isDisabled ? "#ebebeb" : "",
            }),
            menu: (menuStyles) => ({
              ...menuStyles,
              width: "max-content",
              minWidth: "100%",
              backgroundColor: theme.palette.grey[400],
              color: theme.palette.text.primary,
              marginTop: 0,
              boxShadow: "none",
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              fontWeight: 500,
              "&:hover": {
                backgroundColor: theme.palette.grey[400],
              },
            }),
            option: (optionStyles, state) => ({
              ...optionStyles,
              "&:hover": !state.isDisabled
                ? {
                    backgroundColor: "#dce0eb",
                    "& button": { backgroundColor: "#dce0eb" },
                  }
                : {},
              backgroundColor: state?.isSelected ? "#dce0eb" : theme.palette.grey[400],
              color: state.isDisabled ? theme.palette.tertiary[600] : theme.palette.text.primary,
            }),
            menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
          }
        }
        formatOptionLabel={customOption}
      />
    </>
  )
}

export default ZtnaSelect
