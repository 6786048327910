import { createUseStyles } from "react-jss"
import {
  APP_LAYOUT_GRID_ROW_GAP,
  APP_MARGIN,
  CLOSED_DRAWER_WIDTH,
  DRAWER_WIDTH,
  TOP_NAV_HEIGHT,
} from "src/utils/constants"

type Props = {
  isOpened?: boolean
  iconName?: string
}

const NAV_OPTION_HEIGHT = 52

export const useSideNavStyles = createUseStyles<string, Props>((theme) => ({
  Popover: {
    padding: 13,
    boxShadow: "0 3px 9px 0 rgba(120, 120, 120, 0.15)",
  },
  PopoverHidden: {
    display: "none",
  },
  sticky: {
    position: "sticky",
    top: 100 + APP_MARGIN,
    zIndex: theme.zIndex.sideNav,
  },
  drawerPaper: {
    borderRadius: 8,
    height: `calc(100vh - ${TOP_NAV_HEIGHT}px - ${APP_MARGIN * 2}px - ${APP_LAYOUT_GRID_ROW_GAP}px)`,
    overflowY: "overlay",
    overflowX: "hidden",
    backgroundColor: theme.palette.background.surfaceElevation1,
    columns: "span 1",
    paddingTop: 44,
    whiteSpace: "nowrap",
    width: DRAWER_WIDTH,
    transition: "width 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
    "&::-webkit-scrollbar": {
      width: 6,
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.grey[700],
    },
  },
  drawerClose: {
    borderRadius: 8,
    backgroundColor: theme.palette.background.surfaceElevation1,
    gridColumn: "span 1",
    whiteSpace: "nowrap",
    width: CLOSED_DRAWER_WIDTH,
    height: CLOSED_DRAWER_WIDTH,
    transition: "all 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
  },
  navCollapsedItems: {
    display: "flex",
    flexDirection: "column",
    minHeight: 66,
    justifyContent: "center",
    "& svg": {
      marginBottom: 4,
    },
  },
  navCollapsedItemsPopover: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    height: 66,
  },
  navCollapsedNodeText: {
    fontSize: 11,
    fontWeight: 600,
    color: theme.palette.text.primary,
    textAlign: "center",
    whiteSpace: "initial",
  },
  hiddenDrawer: {
    width: 0,
  },
  navContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 0,
    gridArea: "SideNav",
  },
  sideNavPopoverNodeText: {
    fontSize: 11,
    fontWeight: 600,
    color: theme.palette.grey[500],
    textAlign: "left",
    whiteSpace: "initial",
  },
  listRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    backgroundColor: theme.palette.background.surfaceElevation0,
  },
  collapseNavItem: {
    width: "100%",
  },
  collapsedNavIcon: {
    "&.MuiListItemIcon-root": {
      width: "100%",
      justifyContent: "center",
    },
  },
  expandedBG: {
    marginLeft: -18,
  },
  navItem: {
    backgroundColor: "inherit",
    color: theme.palette.background.inverseEmphasis,
    display: "flex",
    justifyContent: "start",
    gap: 20,
    alignItems: "center",
    fontSize: 16,
    paddingLeft: 15,
    height: 40,
  },
  navItemText: {
    flex: "none",
  },
  navNodeText: {
    fontSize: 18,
    fontWeight: 400,
    color: theme.palette.grey[300],
  },

  navChildText: {
    fontSize: 16,
    color: theme.palette.text.primary,
    fontWeight: 400,
  },
  selectedText: {
    color: `${theme.palette.background.accentDefault} !important`,
    fontWeight: 700,
    fontSize: 14,
  },

  listDivider: {
    width: "80%",
    marginLeft: "auto",
  },
  collapseBtn: {
    "&.MuiListItemIcon-root": {
      minWidth: "unset",
    },
  },
  navIcon: {
    "&.MuiListItemIcon-root": {
      minWidth: 17.5,
    },
    marginRight: 10,
  },
  collapseBtnOpen: {
    transform: "rotate(180deg)",
  },
  navMenuContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: `calc(100vh - ${TOP_NAV_HEIGHT}px - ${
      APP_MARGIN * 2
    }px - ${APP_LAYOUT_GRID_ROW_GAP}px - 5px - ${NAV_OPTION_HEIGHT}px)`,
    marginTop: NAV_OPTION_HEIGHT,
  },
  versionInfo: {
    color: theme.palette.neutralDark[9],
    padding: 21,
  },
  versionInfoText: {
    fontSize: 14,
    fontWeight: 400,
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 14,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 8,
    paddingBottom: 8,
  },
  arrow: {
    color: theme.palette.primary.main,
  },

  navItemTitleContainer: (props) => ({
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    fontSize: 16,
    gap: 20,
    paddingLeft: props?.iconName ? 15 : 70,
    height: 40,
  }),
  navItemTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.background.inverseEmphasis,
  },
  menuButtonContainerOpened: {
    position: "absolute",
    top: 0,
    backgroundColor: theme.palette.background.surfaceElevation1,
    borderRadius: 8,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    transition: "margin 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
  },
  menuButtonContainerClosed: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "calc(var(--en-z-index-top) + 3)",
  },
}))
